
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import Campana from '@/store/entities/OZONE/campana'
import BaseDatePicker from '@/components/Inputs/BaseDatePicker.vue'
import PageRequest from '@/store/entities/page-request'
import SelectCliente from '../cliente/selectCliente.vue'
import SelectMarca from '../marca/selectMarca.vue'
import Cliente from '@/store/entities/OZONE/cliente'
import Marca from '@/store/entities/OZONE/marca'
class PageCampanaRequest extends PageRequest {
  keyword: string
  fechaInicio: Date
  fechaFin: Date
  cliente: Cliente
  marca: Marca
}

@Component({
  components: { Modal, BaseButton, BaseCheckbox, BaseDatePicker, SelectCliente, SelectMarca }
})
export default class FindCampana extends AbpBase {
  name: 'find-campana'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: String }) id: string
  @Prop() pagerequest: PageCampanaRequest

  cliente: Cliente = null

  async created() {}
  save() {
    this.$emit('find-success', this.pagerequest)
    this.$emit('input', false)
    this.resetFields()
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  async shown() {}
  checkFormValidity() {
    const valid = (this.$refs.campanaForm as any).checkValidity()
    return valid
  }
  resetFields() {}
}
