
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '../../../lib/abpbase'
import Campana from "../../../store/entities/OZONE/campana";
import { locking } from '@/router/router';
import controlDuplicadosDato from '@/store/entities/OZONE/controlDuplicadosDato';

@Component({
    components: {
    }
})
export default class EditControlDuplicadoCampana extends AbpBase {
    @Prop({ type: Boolean, default: false }) value: boolean
    @Prop({ type: String, default: 'Añadir control de duplicado' }) title: string
    @Prop({ type: Number, default: null }) campanaId: number
    @Prop({ type: Array, default: null }) controlledCampos: Array<controlDuplicadosDato>
    name: 'control-duplicados-participacion-dato'

    selectedCampos: Array<controlDuplicadosDato> = [];
    controlEmail: boolean = false;
    controlPhoneNumber: boolean = false;
    controlTicketNumber: boolean = false;

    get camposDisponibles(){
        var camposDeCampana = this.$store.state.campana.camposList;
        //var disponibles = camposDeCampana.filter(cc => !this.controlledCampos.some(cp => cp.controlCampoId === cc.id));
        var disponibles = camposDeCampana;
        return disponibles
    }

    shown(){
        console.log("Shown..");
    }

    mounted(){
        this.resetData();
    }

    alreadyChecked(campoId: number){
        return this.controlledCampos.find(e => e.controlCampoId == campoId) != null;
    }

    datoCheked(campoId: number){
        console.log("dato checked..")
        if(!this.alreadyChecked(campoId)){
            let newControl : controlDuplicadosDato = {
              campanaId: this.campanaId,
              controlCampoId: campoId,
              controlType: 4,
              controlDuplicadosId: -1,
              campo: "Dato Participacion",
              controlValue: true,
              controlCampanaId: null,
              controlCampanaName: null,
              id: 0
            };

            this.controlledCampos.push(newControl);
        }
        else{
            var index = this.controlledCampos.findIndex(e => e.controlCampoId == campoId);
            this.controlledCampos.splice(index, 1);
        }
    }

    handleOk(){
        console.log("Guardamos datos de participacion: ");
        console.log(JSON.stringify(this.controlledCampos));
        this.resetData();
        this.$emit("input", false);
    }

    getDatos(){

    }

    handleCancel(){
        this.$emit("input", false);
    }

    resetData(){
        this.selectedCampos = [];
    }
}
