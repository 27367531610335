

import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '../../../lib/abpbase'
import Util from '../../../lib/util'
import Campana from '@/store/entities/OZONE/campana'
import EditControlDuplicadoCampana from "./edit-controlCampanaDuplicado.vue"
import EditControlParticipacionDato from "./edit-participacion-dato-duplicado.vue"
import ControlDuplicadosDato from '@/store/entities/OZONE/controlDuplicadosDato'

@Component({
    components: {
        EditControlDuplicadoCampana,
        EditControlParticipacionDato
    }
})
export default class ControlDuplicados extends AbpBase {
    name: 'control-duplicados';
    title: string = '';

    //activeKey: Array<string> = ["1"];
    campana: Campana = new Campana();
    campanaId = 0;
    controlDuplicadosDeCampanaAgrupados = []

    controlParticipacionDato = [];
    controlCamposDuplicados = [];
    modalTitle: string = "";
    showModalEdit: boolean = false;
    showModalEditControlParticipacionDato: boolean = false;
    controlParticipationData = [];
    controlCamposParticipacion = [];
    controlTicketData = [];
    controlOtherCampaings = [];
    
    controlDuplicadoCampanas = []
    
    created(){
        this.campana = Util.extend(true, {}, this.$store.state.campana.editCampana)
        this.campanaId = this.campana.id;
        this.controlDuplicadoCampanas = [];
        this.$store.dispatch({
            type: 'campana/GetAllControlDuplicadosForEdit',
            data: { campanaId: this.campanaId }
        }).then(value => {
            this.controlCamposDuplicados = value;
            value.forEach((element, index) => {
                if(element.controlType === 1){
                    this.controlParticipationData.push(element);
                }
                if(element.controlType === 2){
                    this.controlTicketData.push(element);
                }
                if(element.controlType === 3 && element.controlCampanaId !== null){
                    this.controlOtherCampaings.push(element);
                }
                if(element.controlType == 4 && element.controlCampoId != null){
                    this.controlCamposParticipacion.push(element);
                }
            });

            this.setControlOtherCampaingsForView();
        })
    }

    async beforeDestroy(){
        this.separateControlOtherCampaings();
        this.controlCamposDuplicados = this.controlCamposDuplicados.concat(this.controlCamposParticipacion);
        await this.$store.dispatch({
          type: 'campana/updateControlDuplicadosDato',
          data: this.controlCamposDuplicados
        })
    }

    switched(checked, controlDuplicadosId){
    }

    deleteParticipacionDatoControl(campoId: number){
        var index = this.controlCamposParticipacion.findIndex(c => c.controlCampoId === campoId);
        var element = this.controlCamposParticipacion[index];

        if(element.id > 0){
            this.$store.dispatch({
                type: 'campana/deleteControlDuplicadosDato',
                data: element
            });
        }
        else{
            this.controlCamposParticipacion.splice(index, 1);
        }
    }

    addParticipacionDatoControl(){
        this.showModalEditControlParticipacionDato = true;
        this.modalTitle = "Añadir control de duplicado"
    }
    
    addCampanaControl(){
        this.showModalEdit = true;
        this.modalTitle = "Añadir control de duplicado";
    }

    saveControlDuplicado(newControl){
        this.controlDuplicadoCampanas.push(newControl);
    }

    deleteControl(index){
        this.controlDuplicadoCampanas.splice(index, 1);
    }

    async deleteControlDuplicadoOtherCampaing(item, index){
        let campanaControlId = item.campanaId;

        this.controlDuplicadoCampanas.splice(index, 1);
        this.controlCamposDuplicados = this.controlCamposDuplicados.filter(item => item.controlCampanaId != campanaControlId);

        let controlsToDelete = this.controlOtherCampaings.filter(item => item.controlCampanaId == campanaControlId);
        controlsToDelete.forEach(async element => {
            await this.$store.dispatch({
                type: 'campana/deleteOtrasCampanasControlDuplicadosDato',
                data: element
            })
        });
    }

    getCampoNombre(campoId: number){
        var element = this.$store.state.campana.camposList.find(e => e.id === campoId)
        return element.nombre;
    }

    setControlOtherCampaingsForView(){
        this.controlDuplicadosDeCampanaAgrupados = this.groupBy(this.controlOtherCampaings, 'controlCampanaId');
        let keys = Object.keys(this.controlDuplicadosDeCampanaAgrupados);
        
        keys.forEach(key => {
            let group = this.controlDuplicadosDeCampanaAgrupados[key];
            let obj = {
                campanaId: group[0].controlCampanaId,
                campanaNombre: group[0].controlCampanaName,
                emailControl: group[0] ? group[0].controlValue : false,
                phoneControl: group[1] ? group[1].controlValue : false,
                ticketControl: group[2] ? group[2].controlValue : false
            }

            this.controlDuplicadoCampanas.push(obj);
        })
    }

    separateControlOtherCampaings(){
        this.controlDuplicadoCampanas.forEach((item, index) => {
            let campanaControlId = item.campanaId;
            
            if(!this.controlDuplicadosDeCampanaAgrupados[campanaControlId]){
                let emailControl = {id: 0, controlDuplicadosId: 8, campanaId: this.campanaId, controlCampanaId: campanaControlId, controlValue: item.emailControl}
                let phoneControl = {id: 0, ControlDuplicadosId: 9, campanaId: this.campanaId, controlCampanaId: campanaControlId, controlValue: item.phoneControl}
                let ticketControl = {id: 0, controlDuplicadosId: 10, campanaId: this.campanaId, controlCampanaId: campanaControlId, controlValue: item.ticketControl}

                this.controlCamposDuplicados.push(emailControl)
                this.controlCamposDuplicados.push(phoneControl)
                this.controlCamposDuplicados.push(ticketControl)
            }
        })
    }

    groupBy(input, key){
        return input.reduce((acc, currentValue) => {
            let groupKey = currentValue[key];
            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }
            acc[groupKey].push(currentValue);
            return acc;
        }, {});
    };

    columnsDuplicado = [
        {
            title: this.L("Campo"),
            key: "campo",
            dataIndex: "campo",
            width: 12
        },
        { 
            title: 'Control de duplicado',
            key: 'switchControl', 
            scopedSlots: { customRender: 'switchControl' },
            width: 12
            //fixed: "right",
        }
    ]

    columnsControlCamposParticipacion = [
        {
            title: this.L("Campo"),
            key: "nombreCampo",
            scopedSlots: { customRender: 'nombreCampo' },
            with: 12
        },
        {
            title: this.L("Acciones"),
            key: "actions",
            scopedSlots: { customRender: 'actions' },
            with: 12
        }
    ]
}
