
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Campana from "../../../store/entities/OZONE/campana";
import editRegalo from "./edit-regalo.vue";
import XLSX from "xlsx";
import PageRequest, { maxResults } from "../../../store/entities/page-request";

class PageEanRequest extends PageRequest {
  campanaId: number;
}

@Component({
  components: {
    editRegalo,
  },
})
export default class Ean extends AbpBase {
  downloadUrl: `$ {process.env.BASE_URL} fileResources / template.xlsx`;
  //filters
  @Prop({ type: Array }) regalos: Array<any>;
  file: File = null;
  pagerequest: PageEanRequest = new PageEanRequest();
  creationTime: Date[] = [];
  campana: Campana = new Campana();
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  listNewRegalo: any = [];
  regalosByCampana: Array<any> = [];
  listRegalos: Array<any> = [];
  pagination: any = {};
  error: string = "";
  mostrarError:boolean=false
  loadingRegalos = false;
  
  get list_regalos() {
    
    return this.$store.state.campana.regalosList;
  }
  
  get loading() {
    this.mostrarError=false;
    return this.$store.state.regalo.loading;
  }

  async create() {
    this.createModalShow = true;
    this.pagerequest = new PageEanRequest();
  }

  editRow(item, index, button) {
      
    this.$store.commit("regalo/edit", item);
    this.edit();
    this.getRegalosByCampana();
  }

  uploadGifts() {
    
    this.mostrarError=false;
    if (this.file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        /* Parse data */

        const bstr = e.target.result;

        const wb = XLSX.read(bstr, { type: "binary" });

        /* Get first worksheet */

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        //INSERCIÓN EAN EXCEL
        //Falta por añadir:
        // - Si un registro tiene algún fallo de validación, sale del bucle y no realiza la inserción
        // - Ver si los campos -importe- y -importe porcentual- son obligatorios o pueden entrar vacios
        // - Mostrar mensaje de error si fallase validación

        const dataExcel = XLSX.utils.sheet_to_json(ws, { header: 2 });
        var ok = true;
        
        dataExcel.forEach((value, index) => {
            
          dataExcel[index]["CampanaId"] =
            this.$store.state.campana.editCampana.id;
          if (dataExcel[index]["Articulo"]) {
            if (
              !dataExcel[index]["ReferenciaCliente"] ||
              !dataExcel[index]["ReferenciaEkon"] ||
              !dataExcel[index]["DescripcionDataCentric"]
            ) {
              ok = false;
              this.error="Los campos ReferenciaCliente, ReferenciaEkon y DescripcionDataCentric al cargar los Regalos son obligatorios."
              this.mostrarError=true;
           
            }
          } 
          else {
            ok = false;
            this.mostrarError=true;
            this.error="Articulo en el registro " + index + " debe estar relleno."
           
            console.error(
              "Regalo en el registro " + index + " no esta relleno."
            );
          }
          if (ok) {
           this.mostrarError=false;
            value['DescripcionDC'] = value['DescripcionDataCentric'];
            delete value['DescripcionDataCentric'];
            this.listNewRegalo.push(value);
       }
          console.log(this.listNewRegalo);
        });

        this.comprobaRepetidos();
      };

      reader.readAsBinaryString(this.file);
    }
  }

  async comprobaRepetidos(){
    let item = this.listNewRegalo;
    await this.$store.dispatch({
    type: "regalo/comprobaRepetidos",
    data: item,
    });
    this.getRegalosByCampana();
    this.listNewRegalo = [];
    this.file = null;
  }

  async leerExcell() {
   
    let item = this.listNewRegalo;
    await this.$store.dispatch({
      type: "regalo/leerExcell",
      data: item,
    });
  }

  deleteAllGifts(){
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar todos los regalos de la campaña?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          this.loadingRegalos = true;
          await this.$store.dispatch({
            type: "regalo/deleteAll",
            data: this.$store.state.campana.editCampana.id,
          });
          
          await this.getRegalosByCampana();
        }
      });
  }

  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Seguro que desea borrar este regalo?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "regalo/delete",
            data: item,
          });

          await this.getRegalosByCampana();
        }
      });
  }

  edit() {
    this.editModalShow = true;
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("regalo/setCurrentPage", pagination.current);
    await this.getpage();
  };

  pageChange(page: number) {
    this.$store.commit("regalo/setCurrentPage", page);
    this.getpage();
  }

  pagesizeChange(pagesize: number) {
    this.$store.commit("regalo/setPageSize", pagesize);
    this.getpage();
  }

  async getpage() {
    this.pagerequest.campanaId = this.campana.id;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
  }
  get pageSize() {
    return this.$store.state.regalo.pageSize;
  }
  get totalCount() {
    return this.$store.state.regalo.totalCount;
  }
  get currentPage() {
    return this.$store.state.regalo.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("regalo/setCurrentPage", page);
    this.getpage();
  }

  async created() {
    
    this.getpage();
  }

  async getRegalosByCampana() {
    this.loadingRegalos = true;
    this.pagerequest.campanaId = this.$store.state.campana.editCampana.id;
    this.pagerequest.maxResultCount = maxResults.maxResultCount;
    this.pagerequest.skipCount = maxResults.skipCount;

    await this.$store.dispatch({
      type: "campana/getAllRegalo",
      data: this.pagerequest,
    });

    this.regalosByCampana = this.$store.state.campana.regalosList;

    this.listRegalos = this.$store.state.regalo.list.filter((x) => {
      return (
        this.regalosByCampana
          .map((sel) => {
            return sel.id;
          })
          .includes(x.id) === false
      );
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.regalo.totalCount;
    // pagination.showTotal = () =>
    //   `${this.$store.state.regalo.totalCount} Regalos.`;
    pagination.pageSize = this.$store.state.regalo.pageSize;
    pagination.current = this.$store.state.regalo.currentPage;
    this.pagination = pagination;
    this.loadingRegalos = false;
  }
  columns = [
    // {
    //   title: '',
    //   scopedSlots: { customRender: "checkLot" },
    // },
    {
      title: this.L("Artículo"),
      dataIndex: "articulo",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Referencia Cliente"),
      dataIndex: "referenciaCliente",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Referencia Ekon"),
      dataIndex: "referenciaEkon",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Descripcion DataCentric"),
      dataIndex: "descripcionDC",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      scopedSlots: { customRender: "editBtn" },
      width: 10,
      title: "",
    },
    {
      scopedSlots: { customRender: "removeBtn" },
      width: 10,
      title: "",
    },
  ];
}
