
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import EditEmail from "./edit-emailEstadoParticipacion.vue";
import Campana from "../../../store/entities/OZONE/campana";
class PageEmailRequest extends PageRequest {
  filter: string;
  campanaId: number;
}

@Component({
  components: {
    EditEmail,
  },
})
export default class EmailEstadoParticipacion extends AbpBase {
  //filters
  pagerequest: PageEmailRequest = new PageEmailRequest();
  creationTime: Date[] = [];
  pagination: any = {};
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  title: String = "";
  campanaId = 0;
  campana: Campana = new Campana();
  get list() {
    return this.$store.state.emailEstadoParticipacion.list;
    ;
  }
  get loading() {
    return this.$store.state.emailEstadoParticipacion.loading;
  }
  create() {
    this.createModalShow = true;
  }
  editRow(item, index, button) {
    this.$store.commit("emailEstadoParticipacion/edit", item);
    this.edit();
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar este email?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "emailEstadoParticipacion/delete",
            data: item,
          });
          await this.getpage();
        }
      });
  }
  edit() {
    this.editModalShow = true;
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit(
      "emailEstadoParticipacion/setCurrentPage",
      pagination.current
    );
    //this.getpage();

    await this.getpage();
  };

  pageChange(page: number) {
    this.$store.commit("emailEstadoParticipacion/setCurrentPage", page);
    this.getpage();
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit("emailEstadoParticipacion/setPageSize", pagesize);
    this.getpage();
  }
  async getpage() {
    this.campana = Util.extend(true, {}, this.$store.state.campana.editCampana);
    this.campanaId = this.campana.id;
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    this.pagerequest.campanaId = this.$store.state.campana.editCampana.id;
    //this.pagerequest.keyword = this.$store.state.campana.editCampana.id;


    await this.$store.dispatch({
      type: "emailEstadoParticipacion/getAll",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.emailEstadoParticipacion.totalCount;
    pagination.showTotal = () =>
      `${this.$store.state.emailEstadoParticipacion.totalCount} Emails.`;
    pagination.pageSize = this.$store.state.emailEstadoParticipacion.pageSize;
    pagination.current = this.$store.state.emailEstadoParticipacion.currentPage;
    this.pagination = pagination;
  }
  get pageSize() {
    return this.$store.state.emailEstadoParticipacion.pageSize;
  }
  get totalCount() {
    return this.$store.state.emailEstadoParticipacion.totalCount;
  }
  get currentPage() {
    return this.$store.state.emailEstadoParticipacion.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("emailEstadoParticipacion/setCurrentPage", page);
    this.getpage();
  }
  columns = [
    {
      title: this.L("Nombre"),
      dataIndex: "nombre",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Alias"),
      dataIndex: "alias",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Asunto"),
      dataIndex: "asunto",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Estado Participación"),
      dataIndex: "estadoParticipacion.nombre",
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.L("Sub Estado Participación"),
      dataIndex: "subEstadoParticipacion.nombre",
      scopedSlots: { customRender: "status" },
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];

  async created() {
    this.getpage();
  }
}
