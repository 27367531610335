
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import { EstadoParticipacion } from '@/store/entities/OZONE/estadoParticipacion'
import SubEstadoParticipacion from '@/store/entities/OZONE/subEstadoParticipacion'
import PageRequest, { maxResults } from '@/store/entities/page-request'
import SelectCallCenter from '../callCenter/selectCallCenter.vue'
import CallCenterEstadoParticipacion from '@/store/entities/OZONE/callCenterEstadoParticipacion'
import SelectCampo from '../campo/selectCampo.vue'
import Campana from '@/store/entities/OZONE/campana'

class EstadosRequest extends PageRequest {
  campanaId: number
}
class PageCamposRequest extends PageRequest {
  campanaId: number
}

@Component({
  components: { Modal, BaseButton, SelectCallCenter, SelectCampo }
})
export default class EditCallCenterEstadoParticipacion extends AbpBase {
  name: 'edit-call-center-estado-participacion'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  loading: boolean = false
  inputEstadoParticipacion: EstadoParticipacion = null
  inputSubEstadoParticipacion: SubEstadoParticipacion = null
  estadosRequest: EstadosRequest = new EstadosRequest()
  subEstadoParticipacionList: Array<SubEstadoParticipacion> = []
  title: string = ''
  callCenter: CallCenterEstadoParticipacion = new CallCenterEstadoParticipacion()
  campanaId = 0
  campana: Campana = new Campana()

  get camposlist() {
    return this.$store.state.grupo.camposList
  }
  get listPropertyType() {
    return this.$store.state.campana.listPropertyType
  }
  created() {}
  async save() {
    if (this.checkFormValidity()) {
      this.loading = true
      this.callCenter.estadoParticipacionId = this.inputEstadoParticipacion.id
      this.callCenter.estadoParticipacion = this.inputEstadoParticipacion
      this.callCenter.subEstadoParticipacionId = this.inputSubEstadoParticipacion != null ? this.inputSubEstadoParticipacion.id : null
      this.callCenter.subEstadoParticipacion = this.inputSubEstadoParticipacion != null ? this.inputSubEstadoParticipacion : null

      this.loading = false
      this.$emit('save-success', this.callCenter)
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    this.loading = true
    this.getEstadoParticipacion()
      .then(() => {
        if (this.editing) {
          this.callCenter = Util.extend(true, {}, this.$store.state.callCenterCampana.editCallCenterEstadoParticipacion)
          this.inputEstadoParticipacion = this.estadoParticipacionList.find((x) => x.id === this.callCenter.estadoParticipacionId)
          // this.GetVozitelEstadoParticipacion(this.$store.state.vozitelCampana.editVozitelEstadoParticipacion.id).then(() => {
          //   this.vozitel = Util.extend(true, {}, this.$store.state.vozitelCampana.editVozitelEstadoParticipacion)

          //   this.inputEstadoParticipacion = this.estadoParticipacionList.find((x) => x.id === this.vozitel.estadoParticipacionId)
          // })
        } else {
          this.inputEstadoParticipacion = null
          this.inputSubEstadoParticipacion = null
        }

        if (this.editing) {
          this.title = 'Editar Estado Call Center'
        } else {
          this.title = 'Crear Estado Call Center'
        }
      })
      .then(() => {
        this.loading = false
      })
  }

  checkFormValidity() {
    const valid = (this.$refs.editCallCenterEstadoParticipacionform as any).checkValidity()
    return valid
  }
  resetFields() {
    this.callCenter = new CallCenterEstadoParticipacion()
  }
  get estadoParticipacionList() {
    return this.$store.state.estadoParticipacion.list
  }
  async getEstadoParticipacion() {
    this.campana = Util.extend(true, {}, this.$store.state.campana.editCampana)
    this.campanaId = this.campana.id
    if (this.campanaId) {
      this.estadosRequest.campanaId = this.campanaId
      await this.$store.dispatch({
        type: 'estadoParticipacion/getAllByCampana',
        data: this.estadosRequest
      })
    } else {
      this.estadosRequest.campanaId = this.$route.params.id as any
      this.campanaId = this.estadosRequest.campanaId
      await this.$store.dispatch({
        type: 'estadoParticipacion/getAllByCampana',
        data: this.estadosRequest
      })
    }
  }
  @Watch('inputEstadoParticipacion')
  async selectEstadoParticipacionChange(value) {
    if (value && value !== null) {
      this.subEstadoParticipacionList = value.subEstadoParticipaciones
      if (this.callCenter.subEstadoParticipacionId != null && this.subEstadoParticipacionList.find((x) => x.id == this.callCenter.subEstadoParticipacionId)) {
        this.inputSubEstadoParticipacion = this.subEstadoParticipacionList.find((x) => x.id == this.callCenter.subEstadoParticipacionId)
      } else {
        this.inputSubEstadoParticipacion = null
      }
    }
  }

  callCenterRule = {
    estadoParticipacion: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('EstadoParticipacion')),
      placeholder: this.L('EstadoParticipacion'),
      trigger: 'blur'
    },
    subEstadoParticipacion: {
      required: { required: false },
      message: this.L('ThisFieldIsRequired', undefined, this.L('SubEstadoParticipacion')),
      placeholder: this.L('SubEstadoParticipacion'),
      trigger: 'blur'
    }
  }
}
