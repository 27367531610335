
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import Ean from "../../../store/entities/OZONE/ean";
import Campana from "../../../store/entities/OZONE/campana";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
class PageEanRequest extends PageRequest {
  eanId: number;
}
@Component({
  components: { Modal, BaseButton },
})
export default class EditEan extends AbpBase {
  name: "edit-ean";
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Boolean, default: false }) editing: boolean;
  @Prop({ type: String }) id: string;
  pagerequest: PageRequest = new PageRequest();
  pageEanRequest: PageEanRequest = new PageEanRequest();
  ean: Ean = new Ean();
  campana: Campana = new Campana;
  title: String = "";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  eansByCampana: Array<any> = [];
  listEans: Array<any> = [];
  get list() {
    return this.$store.state.ean.list;
    // return this.$store.state.campana.eansList
  }
  get list_eans() {
    return this.$store.state.campana.eansList;
  }

  async save() {
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: "ean/update",
          data: this.ean,
        });
      } else {
        await this.$store.dispatch({
          type: "ean/create",
          data: this.ean,
        });
      }
      this.$emit("save-success");
      this.$emit("input", false);
      this.resetFields();

      this.$emit("eansByCampana")
      // this.$router.go(0);
    }
  }
  close() {
    this.$emit("input", false);
    this.resetFields();
  }
  async shown() {
    if (this.editing) {
      this.ean = Util.extend(true, {}, this.$store.state.ean.editEan);
    }
    if (this.editing) {
      this.title = this.L("EditEan");
    } else {
      this.title = this.L("AddEan");
    }
  }
  checkFormValidity() {
    const valid = (this.$refs.eanForm as any).checkValidity();
    return valid;
  }
  resetFields() {
    this.ean = new Ean();
  }

  eanRule = {
    titulo: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Titulo")),
      placeholder: this.L("Titulo"),
      trigger: "blur",
    },
    cod_ean: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Cod_Ean")),
      placeholder: this.L("Cod_Ean"),
      trigger: "blur",
    },
    marca: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Marca")),
      placeholder: this.L("Marca"),
      trigger: "blur",
    },
    categoria: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Categoria")),
      placeholder: this.L("Categoria"),
      trigger: "blur",
    },
    importe_Fijo: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Importe Fijo")),
      placeholder: this.L("Importe Fijo"),
      trigger: "blur",
    },
    importe_Porcentual: {
      required: { required: true, min: 2, max: 32 },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("Importe Porcentual")
      ),
      placeholder: this.L("Importe Porcentual"),
      trigger: "blur",
    },
  };
}
