
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import draggable from "vuedraggable";
import Nested from "../../../components/nested.vue";
import { maxResults } from "../../../store/entities/page-request";
import CampoDrag from "./campo-drag.vue";

@Component({
  components: { draggable, Nested, CampoDrag },
})
export default class SelectGrupo extends AbpBase {
  name: "two-lists";
  @Prop({ type: Array }) value: Array<any>;
  @Prop({ type: Array }) unselected: Array<any>;

  get grupoForTree() {
    return this.$store.state.grupo.grupoForTree;
  }

  id = 1;
  order = 1;

  listOrigin = [];
  listEnd = [];
  listEndNormalized = [];
  listOriginNormalized = [];
  campos = [];
  grupos = [];

  clone() {}
  log(value) {
    // console.log(this.listOrigin)
    // console.log(this.listEnd)
  }

  async mounted() {

    await this.getgrupoForTree();

    this.listOrigin =
      this.value && this.value.length > 0
        ? this.unselected
        : this.$store.state.grupo.grupoTree;
    this.listEnd = this.value;
    this.listOriginNormalized = this.listOrigin;
    this.listEndNormalized = this.value;

    this.checkCamposAlreadySelected();
  }

  async getgrupoForTree() {
    await this.$store.dispatch({
      type: "grupo/getAllForTree",
      data: maxResults,
    });
  }

  checkCamposAlreadySelected(){

    var selectedFields = [];
    var selectedKeys = [];
    let index = 0;
    this.value.forEach((item) => {
      item.elements.forEach((child) => {
        child.selected = false;
        child.unique = false;

        var keys = item.id + "-" + child.id

        if(child.tamanoCampo == null){
          child.tamanoCampo = 24;
        }

        if(child.order == null){
          child.order = index;
        }
        index++;
        
        selectedFields.push(child);
        selectedKeys.push(keys)
      })
    });

    selectedFields.sort((a, b) => {
      return a.order - b.order;
    });

    this.campos = selectedFields;
    this.checkedKeys = selectedKeys;
    this.generateGruposWithOrder();
  }

  emitterOrigin(value) {
    let nuevoGrupo = value.filter(
      (x) => !this.listOriginNormalized.includes(x)
    )[0];
    if (nuevoGrupo) {
      if (nuevoGrupo.campo) {
        let grupoPadre = this.listOriginNormalized.filter(
          (x) => x.id === nuevoGrupo.padreId
        );
        if (grupoPadre.length > 0) {
          let grupoPadreIndex = this.listOriginNormalized.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
          this.listOriginNormalized[grupoPadreIndex].elements.push(nuevoGrupo);
          grupoPadreIndex = this.listEnd.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
        } else {
          let grupoPadreNew = Object.assign(
            {},
            this.listEnd.filter((x) => x.id == nuevoGrupo.padreId).slice()[0]
          );
          grupoPadreNew.elements = [];
          this.listOriginNormalized.push(grupoPadreNew);
          let grupoPadreIndex2 = this.listOriginNormalized.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
          this.listOriginNormalized[grupoPadreIndex2].elements.push(nuevoGrupo);
          let grupoPadreEndIndex = this.listEnd.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
          // if (this.listEnd[estadoPadreEndIndex].elements.length === 1 && this.listEnd[estadoPadreEndIndex].elements[0].id === nuevoEstado.id) {
          //   this.listEnd.splice(estadoPadreEndIndex, 1)
          // }
        }
      } else {
        let grupoPadreDest = this.listOriginNormalized.filter(
          (x) => x.id === nuevoGrupo.id
        );
        if (grupoPadreDest.length > 0) {
          grupoPadreDest[0].elements.concat(nuevoGrupo.elements);
        } else {
          this.listOriginNormalized.push(nuevoGrupo);
        }
      }
      this.listOrigin = this.listOriginNormalized;
    } else {
      this.listOriginNormalized = value;
      this.listEnd.forEach((firstChild) => {
        firstChild.elements.forEach((element) => {
          if (!element.campo) {
            this.listOriginNormalized.push(element);
            firstChild.elements.splice(firstChild.elements.indexOf(element), 1);
          }
        });
      });
    }

    this.$emit("gruposSinSeleccionar", this.listOrigin);
  }

  emitter(value) {
    let nuevoGrupo = value.filter(
      (x) => !this.listEndNormalized.includes(x)
    )[0];
    if (nuevoGrupo) {
      if (nuevoGrupo.campo) {
        let grupoPadre = this.listEndNormalized
          .filter((x) => x.id === nuevoGrupo.padreId)
          .slice();
        if (grupoPadre.length > 0) {
          let grupoPadreIndex = this.listEndNormalized.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
          this.listEndNormalized[grupoPadreIndex].elements.push(nuevoGrupo);
          grupoPadreIndex = this.listOrigin.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
          if (
            grupoPadreIndex >= 0 &&
            this.listOrigin[grupoPadreIndex].elements.length === 1 &&
            this.listOrigin[grupoPadreIndex].elements[0].id === nuevoGrupo.id
          ) {
            this.listOrigin.splice(grupoPadreIndex, 1);
          }
        } else {
          let grupoPadreNew = Object.assign(
            {},
            this.listOrigin.filter((x) => x.id == nuevoGrupo.padreId).slice()[0]
          );
          grupoPadreNew.elements = [];
          this.listEndNormalized.push(grupoPadreNew);
          let grupoPadreIndex = this.listEndNormalized.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
          this.listEndNormalized[grupoPadreIndex].elements.push(nuevoGrupo);
          let grupoPadreOriginIndex = this.listOrigin.findIndex(
            (x) => x.id === nuevoGrupo.padreId
          );
          if (
            this.listOrigin[grupoPadreOriginIndex].elements.length === 1 &&
            this.listOrigin[grupoPadreOriginIndex].elements[0].id ===
              nuevoGrupo.id
          ) {
            this.listOrigin.splice(grupoPadreOriginIndex, 1);
          }
        }
      } else {
        let grupoPadreDest = this.listEndNormalized.filter(
          (x) => x.id === nuevoGrupo.id
        );
        if (grupoPadreDest.length > 0) {
          grupoPadreDest[0].elements = grupoPadreDest[0].elements.concat(
            nuevoGrupo.elements
          );
        } else {
          this.listEndNormalized.push(nuevoGrupo);
        }
      }
      this.listEnd = this.listEndNormalized;
    } else {
      this.listEndNormalized = value;
      this.listOrigin.forEach((firstChild) => {
        firstChild.elements.forEach((element) => {
          if (!element.campo) {
            this.listEndNormalized.push(element);
            firstChild.elements.splice(firstChild.elements.indexOf(element), 1);
          }
        });
      });
    }
    this.$emit("gruposSeleccionados", this.listEndNormalized);
  }

  GrupoRule = {
    Grupo: {
      // required: { required: this.required },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Grupo")),
      placeholder: this.L("Grupo"),
      trigger: "blur",
    },
  };

  //#newcode

  expandedKeys = [];
  autoExpandParent = true;
  checkedKeys = [];
  selectedKeys = [];
  drag = false;
  //treeData = null;

  get draggingInfo() {
    return this.drag ? "under drag" : "";
  }

  get treeData() {
    var data = this.$store.state.grupo.grupoTree.map((e) => {
      return {
        title: e.nombre,
        key: e.id.toString(),
        children: e.elements.map((x) => {
          return {
            title: x.nombre,
            key: x.padreId + "-" + x.id,
          };
        }),
      };
    });

    return data;
  }

  uncheck(key) {
    var treeKey = key;
    var campoId = key.split("-")[1];
    this.removeTreeNodeKey(treeKey);
    this.removeUnselectedCampos([campoId]);
  }

  selectItem(item) {
    var c = this.campos.find((e) => e.id == item.id);
    var ix = this.campos.indexOf(c);
    this.$set(this.campos[ix], "selected", !c.selected);
  }

  onExpand(expandedKeys) {
    this.expandedKeys = expandedKeys;
    this.autoExpandParent = false;
  }

  onCheck(checkedKeys, e = null) {
    this.checkedKeys = checkedKeys;
    var isSelection = e.checked;
    var campos = [];
    var gruposToBeAdded = [];
    
    var campoIds = this.checkedKeys
      .filter((s) => s.indexOf("-") > 0)
      .map((s) => s.split("-")[1]);

    this.$store.state.grupo.grupoTree.forEach((e) =>
      e.elements.forEach((x) => {
        x.selected = false;
        x.unique = false;
        campos.push(x);
      })
    );

    var selectedCampos = campos.filter((e) => campoIds.includes(e.id.toString()));
    var selectedCamposIds = this.getSelectedCamposIds();
    var unselectedCamposIds = this.getUnselectedCamposIds(campoIds, selectedCamposIds);

    this.grupos = gruposToBeAdded;

    if(isSelection){
      this.addSelectedCampos(selectedCampos);
    }
    else{
      this.removeUnselectedCampos(unselectedCamposIds);
    }

    this.generateGruposWithOrder();
  }

  onSelect(selectedKeys, info) {
    this.selectedKeys = selectedKeys;
  }

  checkMove(e) {
    this.generateGruposWithOrder();
  }

  onEnd(e){
    this.reasignCamposOrder(e.oldIndex, e.newIndex);
  }

  //#region -- Auxiliar methods --

  generateGruposWithOrder(){
    this.campos.forEach(c => {
      let padreId = c.padreId;
      let indexOfGroupAlreadyAdded = this.grupos.findIndex(g => g.id == padreId);
      if(indexOfGroupAlreadyAdded == -1){
        let indexOfGrupo = this.$store.state.grupo.grupoTree.findIndex(g => g.id == padreId);
        let grupo = this.$store.state.grupo.grupoTree[indexOfGrupo];
        let grupoCopy = JSON.parse(JSON.stringify(grupo));
        grupoCopy.elements = [];
        grupoCopy.elements.push(c);
        this.grupos.push(grupoCopy);
      }
      else{
        let campoAlreadyAdded = this.grupos[indexOfGroupAlreadyAdded].elements.findIndex(cg => cg.id == c.id) != -1;
        if(!campoAlreadyAdded){
          this.grupos[indexOfGroupAlreadyAdded].elements.push(c);
        }
      }
    });
    //Trigger del evento para reasignar los campos de la campaña.
    this.$emit("campoSeleccionado", this.grupos);
  }

  reasignCamposOrder(oldIndex, newIndex) {
    var newIndexIsGreater = oldIndex < newIndex;
    this.campos.forEach((campo) => {
      if(newIndexIsGreater){
        if(campo.order > oldIndex && campo.order <= newIndex){
          campo.order = campo.order - 1;
        }
      }
      else{
        if(campo.order < oldIndex && campo.order >= newIndex){
          campo.order = campo.order + 1;
        }
      }
    });

    this.campos[newIndex].order = newIndex;
  }

  addSelectedCampos(selectedCampos){
    console.log("START AddSelectedCampos");

    var actualMaxOrder = this.getMaximumActualOrder();
    selectedCampos.forEach((element) => {
        var campoAlreadySelected = this.campos.find(e => e.id == element.id)
        if(campoAlreadySelected == null){
            actualMaxOrder++;
            element.order = actualMaxOrder;
            this.campos.push(element);
        }
      });

      console.log("END AddSelectedCampos |actualMaxOrder" + actualMaxOrder);
  }

  removeUnselectedCampos(unselectedCamposIds){
    unselectedCamposIds.forEach(id => {
        let campoIndex = this.campos.findIndex(cmp => cmp.id == id);
        this.campos.splice(campoIndex, 1);
        this.fixOrderAfterUnSelect(campoIndex);
      })
  }

  removeTreeNodeKey(treeKey){
    let keyIndex = this.checkedKeys.findIndex(k => k == treeKey)
    this.checkedKeys.splice(keyIndex, 1);
  }

  getMaximumActualOrder(){
    if(this.campos.length == 0){
      return 0;
    }

    var maxOrder = Math.max(...this.campos.map(e => {
      return e.order;
    }));

    return maxOrder;
  }

  getSelectedCamposIds(){
    return this.campos.map((campo) => {
      return campo.id;
    });
  }

  getUnselectedCamposIds(treeSelectedIds, actualSelectedIds){
    return actualSelectedIds.filter(function(obj) {
       return treeSelectedIds.indexOf(obj.toString()) == -1;
    });
  }

  fixOrderAfterUnSelect(unselectedIndex){
    this.campos
    .filter(campo => campo.order >= unselectedIndex)
    .forEach(campo => campo.order -= 1);
  }

  //#endregion
}

