
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import Regalo from "../../../store/entities/OZONE/regalo";
import Campana from "../../../store/entities/OZONE/campana";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
class PageRegaloRequest extends PageRequest {
  regaloId: number;
}
@Component({
  components: { Modal, BaseButton },
})
export default class EditEan extends AbpBase {
  name: "edit-regalo";
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Boolean, default: false }) editing: boolean;
  @Prop({ type: String }) id: string;
  pagerequest: PageRequest = new PageRequest();
  pageRegaloRequest: PageRegaloRequest = new PageRegaloRequest();
  regalo: Regalo = new Regalo();
  campana: Campana = new Campana;
  title: String = "";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  regaloByCampana: Array<any> = [];
  listRegako: Array<any> = [];
  

  async save() {
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: "regalo/update",
          data: this.regalo,
        });
      } else {
        await this.$store.dispatch({
          type: "regalo/create",
          data: this.regalo,
        });
      }
      this.$emit("save-success");
      this.$emit("input", false);
      this.resetFields();

      this.$emit("regalosByCampana")
      // this.$router.go(0);
    }
  }
  close() {
    this.$emit("input", false);
    this.resetFields();
  }
  async shown() {
    if (this.editing) {
      this.regalo = Util.extend(true, {}, this.$store.state.regalo.editRegalo);
    }
    if (this.editing) {
      this.title = this.L("EditRegalo");
    } else {
      this.title = this.L("AddRegalo");
    }
  }
  checkFormValidity() {
    const valid = (this.$refs.regaloForm as any).checkValidity();
    return valid;
  }
  resetFields() {
    this.regalo = new Regalo();
  }

  regaloRule = {
    articulo: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Artículo")),
      placeholder: this.L("Artículo"),
      trigger: "blur",
    },
    referenciaCliente: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Referencia Cliente")),
      placeholder: this.L("Referencia Cliente"),
      trigger: "blur",
    },
    referenciaEkon: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Referencia Ekon")),
      placeholder: this.L("Referencia Ekon"),
      trigger: "blur",
    },
    descripcionDC: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Descripcion DataCentric")),
      placeholder: this.L("Descripcion DataCentric"),
      trigger: "blur",
    },   
  };
}
