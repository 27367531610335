
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseSwitch from '@/components/Inputs/BaseSwitch.vue'
import EmailEstadoParticipacion from '@/store/entities/OZONE/emailEstadoParticipacion'
import { EstadoParticipacion } from '@/store/entities/OZONE/estadoParticipacion'
import SubEstadoParticipacion from '@/store/entities/OZONE/subEstadoParticipacion'
import PageRequest, { maxResults } from '@/store/entities/page-request'
import tinymce from 'vue-tinymce-editor'
import editorApp from '../../../components/editor/tinymce.vue'
class EstadosRequest extends PageRequest {
  campanaId: number
}
class PageCamposRequest extends PageRequest {
  campanaId: number
}
@Component({
  components: { Modal, BaseButton, BaseCheckbox, BaseSwitch, tinymce, editorApp }
})
export default class EditEmailEstadoParticipacion extends AbpBase {
  name: 'edit-email-estado-participacion'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string

  loading: boolean = false
  email: EmailEstadoParticipacion = new EmailEstadoParticipacion()
  inputEstadoParticipacion: EstadoParticipacion = null
  inputSubEstadoParticipacion: SubEstadoParticipacion = null
  estadosRequest: EstadosRequest = new EstadosRequest()
  subEstadoParticipacionList: Array<SubEstadoParticipacion> = []
  subEstadoParticipacionSolicitudConsumidorList: Array<SubEstadoParticipacion> = []
  title: string = ''
  camposRequest: PageCamposRequest = new PageCamposRequest()
  camposByCampana: Array<any> = []
  data: string = ''
  options1 = { height: 300, language: 'es' }
  toolbar2 = 'fullscreen image'
  plugins = ['advlist autolink lists link image charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table contextmenu directionality', 'template paste textcolor colorpicker textpattern imagetools toc help emoticons hr codesample']
  ccInfo = "En caso de varios destinatarios, separar las cuentas de correo con ';'"

  esSolicitudConsumidor: boolean = false;
  solicitudConsumidorOpciones = {
    campos: [],
    estadoValidoId: 0,
    subEstadoValidoId: 0
  }
  estadoValidoSolicitud: EstadoParticipacion = null
  subEstadoValidoSolicitud: SubEstadoParticipacion = null

  created() {}
  async save() {
    if (this.checkFormValidity()) {
      this.loading = true
      this.email.estadoParticipacionId = this.inputEstadoParticipacion.id
      this.email.subEstadoParticipacionId = this.inputSubEstadoParticipacion != null ? this.inputSubEstadoParticipacion.id : null
      this.email.contenido = this.data
      if(this.esSolicitudConsumidor){
        this.email.solicitudConsumidor = true;
        this.solicitudConsumidorOpciones.estadoValidoId = this.estadoValidoSolicitud.id;
        this.solicitudConsumidorOpciones.subEstadoValidoId = this.estadoValidoSolicitud != null ? this.subEstadoValidoSolicitud.id : null;
        this.email.solicitudConsumidorOpciones = JSON.stringify(this.solicitudConsumidorOpciones);
      }
      else{
        this.email.solicitudConsumidor = false;
        this.email.solicitudConsumidorOpciones = null;
      }
      
      if (this.editing) {
        await this.$store.dispatch({
          type: 'emailEstadoParticipacion/update',
          data: this.email
        })
      } else {
        await this.$store.dispatch({
          type: 'emailEstadoParticipacion/create',
          data: this.email
        })
      }
      this.loading = false
      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    this.data = ''
    this.getEstadoParticipacion().then(() => {
      if (this.editing) {
        this.email = Util.extend(true, {}, this.$store.state.emailEstadoParticipacion.editEmailEstadoParticipacion)
        this.data = this.email.contenido
        this.esSolicitudConsumidor = this.email.solicitudConsumidor;
        this.solicitudConsumidorOpciones = JSON.parse(this.email.solicitudConsumidorOpciones);
        this.inputEstadoParticipacion = this.estadoParticipacionList.find((x) => x.id == this.email.estadoParticipacion.id)
        this.inputSubEstadoParticipacion = this.email.subEstadoParticipacion != null ? this.subEstadoParticipacionList.find((x) => x.id == this.email.subEstadoParticipacion.id) : null
        this.estadoValidoSolicitud = this.solicitudConsumidorOpciones != null ? this.estadoParticipacionList.find((x) => x.id == this.solicitudConsumidorOpciones.estadoValidoId) : null;
        this.subEstadoValidoSolicitud = this.estadoValidoSolicitud != null ? this.subEstadoParticipacionSolicitudConsumidorList.find((x) => x.id == this.solicitudConsumidorOpciones.subEstadoValidoId) : null
        this.title = 'Editar Email'
      } 
      else {
        this.inputEstadoParticipacion = null
        this.inputSubEstadoParticipacion = null

        this.estadoValidoSolicitud = null
        this.subEstadoValidoSolicitud = null

        this.data = ''
        this.esSolicitudConsumidor = false;
        this.title = 'Crear Nuevo Email'
      }

      this.getCamposByCampana()
    })
  }

  checkFormValidity() {
    const valid = (this.$refs.emailForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.email = new EmailEstadoParticipacion()
  }
  get estadoParticipacionList() {
    
    return this.$store.state.estadoParticipacion.list
  }
  async getEstadoParticipacion() {
    this.loading = true
    if (this.email.campanaId) {
      this.estadosRequest.campanaId = this.email.campanaId
      await this.$store.dispatch({
        type: 'estadoParticipacion/getAllByCampana',
        data: this.estadosRequest
      })
    } else {
      this.estadosRequest.campanaId = this.$store.state.campana.editCampana.id
      
      this.email.campanaId = this.estadosRequest.campanaId
      await this.$store.dispatch({
        type: 'estadoParticipacion/getAllByCampana',
        data: this.estadosRequest
      })
    }
    this.loading = false
  }
  @Watch('inputEstadoParticipacion')
  async selectEstadoParticipacionChange(value) {
    if (value && value !== null) {
      this.subEstadoParticipacionList = value.subEstadoParticipaciones
      if (this.email.subEstadoParticipacion != null && this.subEstadoParticipacionList.find((x) => x.id == this.email.subEstadoParticipacion.id)) {
        this.inputSubEstadoParticipacion = this.subEstadoParticipacionList.find((x) => x.id == this.email.subEstadoParticipacion.id)
      } else {
        this.inputSubEstadoParticipacion = null
      }
    }
  }

  @Watch('estadoValidoSolicitud')
  async selectEstadoValidoSolicitudChange(value) {
    if (value && value !== null) {
      this.subEstadoParticipacionSolicitudConsumidorList = value.subEstadoParticipaciones
      if(this.subEstadoParticipacionSolicitudConsumidorList.find((x) => x.id == this.solicitudConsumidorOpciones.subEstadoValidoId)){
        this.subEstadoValidoSolicitud = this.subEstadoParticipacionSolicitudConsumidorList.find((x) => x.id == this.solicitudConsumidorOpciones.subEstadoValidoId)
      }
      else{
        this.subEstadoValidoSolicitud = null;
      }
    }
  }

  initCallBack(e) {
    console.log((this.$refs.tm as any).editor)
    console.log('init', e)
  }
  editorChange(e) {
    console.log('change', e)
  }

  async getCamposByCampana() {
    if (this.email.campanaId) {
      this.camposRequest.campanaId = this.email.campanaId
    } else {
      this.estadosRequest.campanaId = this.$route.params.id as any
    }
    this.camposRequest.maxResultCount = maxResults.maxResultCount
    this.camposRequest.skipCount = maxResults.skipCount
    await this.$store
      .dispatch({
        type: 'campana/getAllCampos',
        data: this.camposRequest
      })
      .then(() => {
        this.camposByCampana = []
        this.camposByCampana.push({ nombre: 'Campaña' })
        this.camposByCampana.push({ nombre: 'CampanaId' })
        this.camposByCampana.push({ nombre: 'ParticipacionId' })
        this.camposByCampana.push({ nombre: 'Nombre' })
        this.camposByCampana.push({ nombre: 'Apellido1' })
        this.camposByCampana.push({ nombre: 'Apellido2' })
        this.camposByCampana.push({ nombre: 'Email' })
        this.camposByCampana.push({ nombre: 'Telefono' })
        this.camposByCampana.push({ nombre: 'FechaNacimiento' })
        this.camposByCampana.push({ nombre: 'DNI' })

        this.camposByCampana = this.camposByCampana.concat(this.$store.state.campana.camposList)
      })
  }

  checkOptionSC(e){
    console.log("Se ha checkeado una opcion!");
    //console.log(JSON.stringify(this.solicitudConsumidorOpciones.campos))
  }

  emails = '[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}'

  plainOptions = [
    {
      label: 'DNI',
      value: 'DNI'
    },
    // {
    //   label: 'Telefono',
    //   value: 'Telefono'
    // },
    {
      label: 'Imagen DNI anterior',
      value: 'DNI_Frontal'
    },
    {
      label: 'Imagen DNI posterior',
      value: 'DNI_Trasero'
    },
    {
      label: 'Imagen Auxiliar',
      value: 'DocumentoAux1'
    },
    {
      label: 'Imagen Auxiliar 2',
      value: 'DocumentoAux2'
    },
    {
      label: 'Imagen Auxiliar 3',
      value: 'DocumentoAux3'
    },
    {
      label: 'Imagen Auxiliar 4',
      value: 'DocumentoAux4'
    },
  ];

  emailRule = {
    nombre: {
      required: { required: true, min: 2 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Nombre')),
      placeholder: this.L('Nombre'),
      trigger: 'blur'
    },
    alias: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Alias')),
      placeholder: this.L('Alias'),
      trigger: 'blur'
    },
    asunto: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Asunto')),
      placeholder: this.L('Asunto'),
      trigger: 'blur'
    },
    estadoParticipacion: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('EstadoParticipacion')),
      placeholder: this.L('EstadoParticipacion'),
      trigger: 'blur'
    },
    subEstadoParticipacion: {
      required: { required: false },
      message: this.L('ThisFieldIsRequired', undefined, this.L('SubEstadoParticipacion')),
      placeholder: this.L('SubEstadoParticipacion'),
      trigger: 'blur'
    },
    emailMasivo: {
      required: { required: false, regex: '^' + this.emails + '(;\n*' + this.emails + ')*;?$' },
      message: this.L('ThisFieldIsRequired', undefined, this.L('OtrosDestinatarios')),
      placeholder: this.L('OtrosDestinatarios'),
      trigger: 'blur'
    }
  }
}
