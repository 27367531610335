import Entity from '../entity'
import Campo from './campo';
import { EstadoParticipacion } from './estadoParticipacion';
import SubEstadoParticipacion from './subEstadoParticipacion';
export default class DocumentoLegal extends Entity<number>{

     campanaId : number;
     urlPublico : string;
     tipo: string;
     fichero: string;
     ficheroContent: string;
     vistas: number;
    
}