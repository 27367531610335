
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import BaseCheckbox from "../../../components/Inputs/BaseCheckbox.vue";
import Participacion from "../../../store/entities/OZONE/participacion";
import BaseDatePicker from "../../../components/Inputs/BaseDatePicker.vue";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import ParticipacionDato from "../../../store/entities/OZONE/participacionDato";
import ParticipacionComentario from "../../../store/entities/OZONE/participacionComentario";
import SelectCampana from "../campana/selectCampana.vue";
import { EstadoParticipacion } from "../../../store/entities/OZONE/estadoParticipacion";
import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";
import RuleCheckbox from "../campo/ruleCheckbox.vue";
import FileAgent from "../../../components/fileAgent.vue";
import { Alert, Result } from "ant-design-vue";
import Ticket from "../../../store/entities/OZONE/ticket";
import Campana from "../../../store/entities/OZONE/campana";
import PeopleHeader from "../../../components/People/people-header.vue";
import Transferencia from "../../../store/entities/OZONE/transferencia";
import TransferenciaHistory from "../transferencia/transferencia-history.vue";
import ValidateImagenCampana from "../../../store/entities/OZONE/validateImagenCampana";
class PageCamposRequest extends PageRequest {
  campanaId: number;
}
class EstadosRequest extends PageRequest {
  campanaId: number;
  participacionId: number;
}
class PaticipacionDatoRequest extends PageRequest {
  participacionId: number;
}

@Component({
  components: {
    Modal,
    BaseButton,
    BaseCheckbox,
    BaseDatePicker,
    SelectCampana,
    RuleCheckbox,
    FileAgent,
    PeopleHeader,
    TransferenciaHistory,
  },
})
export default class SelectImagen extends AbpBase {
  name: "selectImagen";
  
  //@Prop({ type: Boolean, default: false }) value: boolean
  //@Prop({ type: Boolean, default: false }) editing: boolean
  //@Prop({ type: String }) id: string
  title : String;
  checkedTicket1:boolean=false;
  checkedTicket2:boolean=false;
  checkedEan:boolean=false;
  checkedDNIFrontal:boolean=false;
  checkedDNITrasero:boolean=false;
  checkedImagenAuxiliar:boolean=false;
  checkedImagenAuxiliar1:boolean=false;
  checkedImagenAuxiliar2:boolean=false;
  checkedImagenAuxiliar3:boolean=false;
  checkedImagenAuxiliar4:boolean=false;
  id: number
  validateImagenCampana = new ValidateImagenCampana ();
  form: any;

async created(){
    
  await this.$store
      .dispatch({
        type: 'campana/getvalidateImagenCampana',
        id: this.$store.state.campana.editCampana.id
      })
      
  if(this.$store.state.campana.validateImagenCampana != null) {
  this.checkedTicket1 = this.$store.state.campana.validateImagenCampana.checkedTicket1
  this.checkedTicket2 = this.$store.state.campana.validateImagenCampana.checkedTicket2
  this.checkedEan = this.$store.state.campana.validateImagenCampana.checkedEan
  this.checkedDNIFrontal = this.$store.state.campana.validateImagenCampana.checkedDNIFrontal
  this.checkedDNITrasero = this.$store.state.campana.validateImagenCampana.checkedDNITrasero
  this.checkedImagenAuxiliar = this.$store.state.campana.validateImagenCampana.checkedImagenAuxiliar
  this.checkedImagenAuxiliar1 = this.$store.state.campana.validateImagenCampana.checkedImagenAuxiliar1
  this.checkedImagenAuxiliar2 = this.$store.state.campana.validateImagenCampana.checkedImagenAuxiliar2
  this.checkedImagenAuxiliar3 = this.$store.state.campana.validateImagenCampana.checkedImagenAuxiliar3
  this.checkedImagenAuxiliar4 = this.$store.state.campana.validateImagenCampana.checkedImagenAuxiliar4
  this.id = this.$store.state.campana.validateImagenCampana.id
  
  this.validateImagenCampana = this.$store.state.campana.validateImagenCampana
  this.validateImagenCampana.campanaId = this.$store.state.campana.editCampana.id
  this.validateImagenCampana.id = this.id
  this.$store.commit("campana/setValidateImagenCampana", this.validateImagenCampana);
  }
}

changeCheckedImage(checkedImage){
  switch(checkedImage){
    case 'checkedTicket1':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedTicket1 = this.checkedTicket1;
      break;
    case 'checkedTicket2':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedTicket2 = this.checkedTicket2;
      break;
    case 'checkedEan':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedEan = this.checkedEan;
      break;
    case 'checkedDNIFrontal':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedDNIFrontal = this.checkedDNIFrontal;
      break;
    case 'checkedDNITrasero':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedDNITrasero = this.checkedDNITrasero;
      break;
    case 'checkedImagenAuxiliar':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedImagenAuxiliar = this.checkedImagenAuxiliar;
      break;
    case 'checkedImagenAuxiliar1':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedImagenAuxiliar1 = this.checkedImagenAuxiliar1;
      break;
    case 'checkedImagenAuxiliar2':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedImagenAuxiliar2 = this.checkedImagenAuxiliar2;
      break;
    case 'checkedImagenAuxiliar3':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedImagenAuxiliar3 = this.checkedImagenAuxiliar3;
      break;
    case 'checkedImagenAuxiliar4':
      this.$store.state.campana.editCampana.validateImagenCampana.checkedImagenAuxiliar4 = this.checkedImagenAuxiliar4;
      break;
    default:
      break;
  }
}
}
