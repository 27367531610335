
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest, { maxResults } from '@/store/entities/page-request'
import CallCenterCampana from '@/store/entities/OZONE/callCenterCampana'
import EditCallCenterEstadoParticipacion from './edit-callCenterEstadoParticipacion.vue'
import SelectCallCenter from '../callCenter/selectCallCenter.vue'
import CamposOzoneACallCenter from '@/store/entities/OZONE/camposOzoneACallCenter'
import CallCenter from '@/store/entities/OZONE/callCenter'
import EditableCell from './editableCell.vue'
import CallCenterEstadoParticipacion from '@/store/entities/OZONE/callCenterEstadoParticipacion'
import { EstadoParticipacion } from '@/store/entities/OZONE/estadoParticipacion'
import Campana from '@/store/entities/OZONE/campana'
class PageCallCenterRequest extends PageRequest {
  campanaId: number
}
class PageCamposRequest extends PageRequest {
  campanaId: number
}

class EstadosRequest extends PageRequest {
  campanaId: number
}

@Component({
  components: {
    EditCallCenterEstadoParticipacion,
    SelectCallCenter,
    EditableCell
  }
})
export default class EditCallCenterCampana extends AbpBase {
  @Prop({
    type: [CallCenterCampana, Object],
    default() {
      return new CallCenterCampana()
    }
  })
  value: CallCenterCampana
  //filters
  pagerequest: PageCallCenterRequest = new PageCallCenterRequest()

  createModalShow: boolean = false
  editModalShow: boolean = false
  title: String = ''
  campanaId = 0
  pagination: any = {}
  callCenter: CallCenterCampana = new CallCenterCampana()
  modelCampo: CamposOzoneACallCenter[] = new Array<CamposOzoneACallCenter>()
  camposByCampana: Array<any> = []
  camposRequest: PageCamposRequest = new PageCamposRequest()
  estadosRequest: EstadosRequest = new EstadosRequest()
  inputEstadoParticipacion: EstadoParticipacion = null
  callCenterEstadoParticipacion: Array<CallCenterEstadoParticipacion> = []
  campana: Campana = new Campana()

  get loading() {
    return this.$store.state.callCenterCampana.loading
  }

  get callCenterCampanaEdit() {
    return this.$store.state.callCenterCampana.editCallCenterCampana
  }

  get estadoParticipacionList() {
    return this.$store.state.estadoParticipacion.list
  }

  log(item) {
    console.log(item)
  }

  create() {
    this.createModalShow = true
  }

  mounted() {
    this.campana = Util.extend(true, {}, this.$store.state.campana.editCampana)
    this.campanaId = this.campana.id
    this.callCenter.vozitel = new CallCenter()
    this.callCenter.vozitelEstadoParticipacion = []
    if (this.campanaId) {
      this.getCallCenter()
    } else {
      this.callCenter = this.value
      if (this.callCenter != null && this.callCenter.camposOzoneAVozitel) {
        this.modelCampo = this.UnParseCampos(this.callCenter.camposOzoneAVozitel)
      }
    }

    this.getCamposByCampana()
    this.getEstadoParticipacion()
    this.$emit('input', this.callCenter)
  }

  async getCallCenter() {
    await this.$store
      .dispatch({
        type: 'callCenterCampana/getByCampana',
        data: { id: this.campanaId }
      })
      .then(() => {
        this.callCenter = this.$store.state.callCenterCampana.editCallCenterCampana
        if (this.callCenter != null) {
          this.modelCampo = this.UnParseCampos(this.callCenter.camposOzoneAVozitel)
          this.callCenterEstadoParticipacion = this.callCenter.vozitelEstadoParticipacion
        } else {
          this.callCenter = new CallCenterCampana()
          this.callCenter.campanaId = this.campanaId
        }
      })
  }
  async beforeDestroy() {
    if (this.callCenter) {
      if (this.callCenter.vozitel) {
        this.callCenter.vozitelId = this.callCenter.vozitel.id
      }
      this.callCenter.camposOzoneAVozitel = this.ParseCampos(this.modelCampo)
      this.callCenter.vozitelEstadoParticipacion = this.callCenterEstadoParticipacion.map((x) => {
        if (!Number.isInteger(x.id)) {
          x.id = 0
        }
        return x
      })
      this.$emit('input', this.callCenter)
    }
  }

  async saveSuccess(item) {
    const dataSource = [...this.callCenterEstadoParticipacion]
    const target = dataSource.find((x) => x.id === item.id)
    if (item.id && target) {
      this.callCenterEstadoParticipacion[dataSource.indexOf(target)] = item
      this.callCenterEstadoParticipacion.splice(0, this.callCenterEstadoParticipacion.length, ...this.callCenterEstadoParticipacion)
    } else {
      item.id = this.generateUUID()
      item.vozitelCampanaId = this.callCenter.id
      this.callCenterEstadoParticipacion.push(item)
    }
  }

  editRow(item, index, button) {
    this.$store.commit('callCenterCampana/editEstados', item)
    this.edit()
  }
  edit() {
    this.editModalShow = true
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('DeleteEstadoCallCenterConfirm'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Yes'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          const dataSource = [...this.callCenter.vozitelEstadoParticipacion]
          const target = dataSource.find((x) => x.id === item.id)
          if (target) {
            this.callCenterEstadoParticipacion.splice(this.callCenterEstadoParticipacion.indexOf(target), 1)
            if (Number(target.id)) {
              await this.$store.dispatch({
                type: 'callCenterCampana/deleteCallCenterEstadoParticipacion',
                data: { id: target.id }
              })
            }
          }
        }
      })
  }

  addCampo() {
    let nuevaRegla = new CamposOzoneACallCenter()
    nuevaRegla.vozitelCampanaId = this.callCenter.id

    this.modelCampo.push(nuevaRegla)
  }

  handleDeleteCampo(campo, index) {
    this.modelCampo.splice(this.modelCampo.indexOf(campo), 1)
  }

  async getCamposByCampana() {
    if (this.campanaId) {
      this.camposRequest.campanaId = this.campanaId
    } else {
      this.estadosRequest.campanaId = this.$route.params.id as any
    }
    this.camposRequest.maxResultCount = maxResults.maxResultCount
    this.camposRequest.skipCount = maxResults.skipCount
    await this.$store
      .dispatch({
        type: 'campana/getAllCampos',
        data: this.camposRequest
      })
      .then(() => {
        this.camposByCampana = []
        this.camposByCampana.push({ nombre: 'Campaña', id: -1 })
        this.camposByCampana.push({ nombre: 'Nombre', id: -2 })
        this.camposByCampana.push({ nombre: 'Apellido1', id: -3 })
        this.camposByCampana.push({ nombre: 'Apellido2', id: -4 })
        this.camposByCampana.push({ nombre: 'Email', id: -5 })
        this.camposByCampana.push({ nombre: 'Telefono', id: -6 })
        this.camposByCampana.push({ nombre: 'FechaNacimiento', id: -7 })
        this.camposByCampana.push({ nombre: 'DNI', id: -8 })

        this.camposByCampana = this.camposByCampana.concat(this.$store.state.campana.camposList)
      })
  }

  ParseCampos(camposOzoneACallCenter: CamposOzoneACallCenter[]): CamposOzoneACallCenter[] {
    let output: CamposOzoneACallCenter[] = new Array<CamposOzoneACallCenter>()

    camposOzoneACallCenter.forEach((element) => {
      if (element.campo) {
        if (element.campo.id < 0) {
          output.push({
            campo: null,
            id: element.id,
            campoCampana: element.campo.nombre,
            campoId: null,
            campoVozitel: element.campoVozitel,
            vozitelCampanaId: element.vozitelCampanaId
          })
        } else {
          output.push({
            campo: null,
            id: element.id,
            campoCampana: null,
            campoId: element.campo.id,
            campoVozitel: element.campoVozitel,
            vozitelCampanaId: element.vozitelCampanaId
          })
        }
      }
    })
    return output
  }

  UnParseCampos(camposOzoneACallCenter: CamposOzoneACallCenter[]): CamposOzoneACallCenter[] {
    let output: CamposOzoneACallCenter[] = new Array<CamposOzoneACallCenter>()
    if (camposOzoneACallCenter) {
      camposOzoneACallCenter.forEach((element) => {
        if (element.campoId == null) {
          output.push({
            campo: { nombre: element.campoCampana, id: -element.id, tipo: 0, listSelectedRules: '' },
            id: element.id,
            campoCampana: element.campoCampana,
            campoId: null,
            campoVozitel: element.campoVozitel,
            vozitelCampanaId: element.vozitelCampanaId
          })
        } else {
          output.push(element)
        }
      })
    }
    return output
  }

  async getEstadoParticipacion() {
    if (this.campanaId) {
      this.estadosRequest.campanaId = this.campanaId
      await this.$store.dispatch({
        type: 'estadoParticipacion/getAllByCampana',
        data: this.estadosRequest
      })
    } else {
      this.estadosRequest.campanaId = this.$route.params.id as any
      this.campanaId = this.estadosRequest.campanaId
      await this.$store.dispatch({
        type: 'estadoParticipacion/getAllByCampana',
        data: this.estadosRequest
      })
    }
  }

  rowKey() {
    return this.generateUUID()
  }
  generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime() //Timestamp
    var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }

  columns = [
    {
      title: this.L('EstadoParticipacion'),
      dataIndex: 'estadoParticipacion.nombre',
      scopedSlots: { customRender: 'name' }
    },
    {
      title: this.L('SubEstadoParticipacion'),
      dataIndex: 'subEstadoParticipacion.nombre',
      scopedSlots: { customRender: 'subEstadoParticipacion' }
    },
    {
      scopedSlots: { customRender: 'editBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: 'removeBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    }
  ]

  callCenterRule = {
    callCenter: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('CallCenter')),
      placeholder: this.L('CallCenter'),
      trigger: 'blur'
    },
    campanaCallCenterId: {
      required: { required: this.callCenter.vozitel != null },
      message: this.L('ThisFieldIsRequired', undefined, this.L('IdCampanaCallCenter')),
      placeholder: this.L('Campaña Call Center'),
      trigger: 'blur'
    },
    estadoCallCenterId: {
      required: { required: this.callCenter.vozitel != null },
      message: this.L('ThisFieldIsRequired', undefined, this.L('EstadoCallCenter')),
      placeholder: this.L('Estado Call Center'),
      trigger: 'blur'
    },
    userCallCenter: {
      required: { required: this.callCenter.vozitel != null },
      message: this.L('ThisFieldIsRequired', undefined, this.L('User')),
      placeholder: this.L('Usuario'),
      trigger: 'blur'
    },
    passwordCallCenter: {
      required: { required: this.callCenter.vozitel != null },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Password')),
      placeholder: this.L('Password'),
      trigger: 'blur'
    }
  }

  async created() {}
}
