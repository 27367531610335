<template>
  <div class="editorcontainer">
    <editor id="app-editor" api-key="jh5j9j89wyv5mbctvucc67e0o5ba3q7v4xkldjfoaqv74glt" :init="inital" v-model="model"> </editor>
  </div>
</template>

<script>
import editor from '@tinymce/tinymce-vue'
import config from './config/tinymce'

export default {
  data() {
    return {}
  },
  props: {
    value: {
      type: [String],
      default: ''
    },
    campos: {
      type: [Array]
    }
  },
  components: {
    editor
  },
  computed: {
    inital() {
      let tiny = config.config
      tiny.campos = this.campos
      if (this.campos.length !== 0) {
        return tiny
      }
    },
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style  scoped>
#app-editor {
  width: 100%;
}

.editorcontainer {
  width: 100%;
}
</style>