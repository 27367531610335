<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <!-- <base-filtrable-dropdown v-model="inputEstadoParticipacion" :options="options" placeholder="EstadoParticipacion" trackby="id" trackfor="nombre" :show-labels="false" :allowempty="false"> </base-filtrable-dropdown> -->
      <multiselect v-model="estadoParticipacion" :options="options" label="nombre" track-by="id" @select="handleChange" :searchable="false" :allow-empty="false" :show-labels="false" open-direction="bottom"> </multiselect>
      <a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ getValue(value) || ' ' }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>
<script>
import CallCenterEstadoParticipacion from '@/store/entities/OZONE/callCenterEstadoParticipacion'
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  props: {
    inputEstadoParticipacion: {
      type: Object,
      default: () => new CallCenterEstadoParticipacion()
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: this.inputEstadoParticipacion,
      editable: false,
      estadoParticipacion: null
    }
  },
  methods: {
    handleChange(e) {
      this.estadoParticipacion = e
    },
    check() {
      this.editable = false
      this.value.estadoParticipacionId = this.estadoParticipacion.id
      this.$emit('change', this.value)
    },
    edit() {
      this.editable = true
      this.estadoParticipacion = this.options.find((x) => x.id === this.value.estadoParticipacionId)
    },
    getValue(value) {
      if (value.estadoParticipacionId && this.options.length > 0) {
        return this.options.find((x) => x.id === value.estadoParticipacionId).nombre
      }
    }
  }
}
</script>