
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Email from '@/store/entities/OZONE/email'
import PageRequest from '@/store/entities/page-request'
class PageEmailRequest extends PageRequest {
  defaultFromDisplayName: string
}
@Component
export default class SelectEmail extends AbpBase {
  @Prop({ type: Object, default: null }) value: Email
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Object, default: null }) rules: Object
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  @Prop({ type: String, default: 'Email' }) name: String
  email: Email = null
  pagerequestEmails: PageEmailRequest = new PageEmailRequest()
  maxResultCountEmail = 10
  skipCountEmail = 0
  get emailList() {
    return this.$store.state.email.list
  }
  get emailCount() {
    return this.$store.state.email.totalCount
  }

  async created() {
    this.getEmailsDropDown()
  }

  async getEmails(params: any) {
    this.pagerequestEmails.defaultFromDisplayName = params
    this.maxResultCountEmail = 10
    this.skipCountEmail = 0
    this.pagerequestEmails.maxResultCount = this.maxResultCountEmail
    this.pagerequestEmails.skipCount = this.skipCountEmail
    await this.$store.dispatch({
      type: 'email/getAll',
      data: this.pagerequestEmails
    })
  }

  async nextPage(offset: number) {
    this.pagerequestEmails.skipCount = offset
    await this.$store.dispatch({
      type: 'email/getAll',
      data: this.pagerequestEmails
    })
  }

  async getEmailsDropDown() {
    this.pagerequestEmails.maxResultCount = this.maxResultCountEmail
    this.pagerequestEmails.skipCount = this.skipCountEmail
    await this.$store.dispatch({
      type: 'email/getAll',
      data: this.pagerequestEmails
    })
  }

  emailRule = {
    email: {
      // required: { required: this.required },
      message: this.L('ThisFieldIsRequired', undefined, this.L('DefaultFromDisplayName')),
      placeholder: this.L('DefaultFromDisplayName'),
      trigger: 'blur'
    }
  }
}
