
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '../../../lib/abpbase'
import SelectCampana from "./selectCampana.vue";
import Campana from "../../../store/entities/OZONE/campana";

@Component({
    components: {
        SelectCampana
    }
})
export default class EditControlDuplicadoCampana extends AbpBase {
    @Prop({ type: Boolean, default: false }) value: boolean
    @Prop({ type: String, default: 'Añadir control de duplicado' }) title: string
    name: 'control-duplicados-campana'

    selectedCampaign: Campana = null;
    controlEmail: boolean = false;
    controlPhoneNumber: boolean = false;
    controlTicketNumber: boolean = false;

    shown(){
       this.resetFields();
    }

    mounted(){
        
    }

    handleOk(){
        console.log(this.selectedCampaign);
        let controlNew = {
            campanaId: this.selectedCampaign.id,
            campanaNombre: this.selectedCampaign.nombre,
            emailControl: this.controlEmail,
            phoneControl: this.controlPhoneNumber,
            ticketControl: this.controlTicketNumber
        }

        this.$emit("save-control", controlNew);
        this.resetFields();
        this.$emit("input", false);
    }

    handleCancel(){
        this.$emit("input", false);
    }

    resetFields(){
        this.selectedCampaign = null;
        this.controlEmail = this.controlPhoneNumber = this.controlTicketNumber = false;
    }
}
