
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '@/lib/abpbase'
import draggable from 'vuedraggable'
import Nested from '@/components/nested.vue'
import { maxResults } from '@/store/entities/page-request'

@Component({
  components: { draggable, Nested }
})
export default class SelectEstados extends AbpBase {
  name: 'two-lists'
  @Prop({ type: Array }) value: Array<any>
  @Prop({ type: Array }) unselected: Array<any>
  @Prop({ type: Number }) campanaTipoId: Array<any>

  get estadosParticipacionForTree() {
    return this.$store.state.estadoParticipacion.estadoParticipacionTree
  }
  Origin = 'Origin'
  Destin = 'Destin'

  display = 'Two Lists'
  order = 1
  listOrigin = []
  listEnd = []
  listEndNormalized = []
  listOriginNormalized = []

  add() {}
  replace() {}
  clone() {}
  log(value) {
    // console.log(this.listOrigin)
    // console.log(this.listEnd)
  }

  async mounted() {
    await this.getEstadosParticipacionTree()
    this.listOrigin = this.value && this.value.length > 0 ? this.unselected : this.$store.state.estadoParticipacion.estadoParticipacionTree
    this.listEnd = this.value
    this.listOriginNormalized = this.listOrigin
    this.listEndNormalized = this.value

    // console.log(this.listOrigin)
    // console.log(this.listEnd)
  }
  async getEstadosParticipacionTree() {
    await this.$store.dispatch({
      type: 'estadoParticipacion/getAllForTree',
      data: { ...maxResults }
    })
  }
  emitterOrigin(value) {
    let nuevoEstado = value.filter((x) => !this.listOriginNormalized.includes(x))[0]
    if (nuevoEstado) {
      if (nuevoEstado.subEstado) {
        let estadoPadre = this.listOriginNormalized.filter((x) => x.id === nuevoEstado.padreId)
        if (estadoPadre.length > 0) {
          let estadoPadreIndex = this.listOriginNormalized.findIndex((x) => x.id === nuevoEstado.padreId)
          this.listOriginNormalized[estadoPadreIndex].elements.push(nuevoEstado)
          estadoPadreIndex = this.listEnd.findIndex((x) => x.id === nuevoEstado.padreId)
        } else {
          let estadoPadreNew = Object.assign({}, this.listEnd.filter((x) => x.id == nuevoEstado.padreId).slice()[0])
          estadoPadreNew.elements = []
          this.listOriginNormalized.push(estadoPadreNew)
          let estadoPadreIndex2 = this.listOriginNormalized.findIndex((x) => x.id === nuevoEstado.padreId)
          this.listOriginNormalized[estadoPadreIndex2].elements.push(nuevoEstado)
          let estadoPadreEndIndex = this.listEnd.findIndex((x) => x.id === nuevoEstado.padreId)
          // if (this.listEnd[estadoPadreEndIndex].elements.length === 1 && this.listEnd[estadoPadreEndIndex].elements[0].id === nuevoEstado.id) {
          //   this.listEnd.splice(estadoPadreEndIndex, 1)
          // }
        }
      } else {
        let estadoPadreDest = this.listOriginNormalized.filter((x) => x.id === nuevoEstado.id)
        if (estadoPadreDest.length > 0) {
          estadoPadreDest[0].elements.concat(nuevoEstado.elements)
        } else {
          this.listOriginNormalized.push(nuevoEstado)
        }
      }
      this.listOrigin = this.listOriginNormalized
    } else {
      this.listOriginNormalized = value
      this.listEnd.forEach((firstChild) => {
        firstChild.elements.forEach((element) => {
          if (!element.subEstado) {
            this.listOriginNormalized.push(element)
            firstChild.elements.splice(firstChild.elements.indexOf(element), 1)
          }
        })
      })
    }
    this.$emit('estadosSinSeleccionar', this.listOrigin)
  }
  emitter(value) {
    let nuevoEstado = value.filter((x) => !this.listEndNormalized.includes(x))[0]
    if (nuevoEstado) {
      if (nuevoEstado.subEstado) {
        let estadoPadre = this.listEndNormalized.filter((x) => x.id === nuevoEstado.padreId).slice()
        if (estadoPadre.length > 0) {
          let estadoPadreIndex = this.listEndNormalized.findIndex((x) => x.id === nuevoEstado.padreId)
          this.listEndNormalized[estadoPadreIndex].elements.push(nuevoEstado)
          estadoPadreIndex = this.listOrigin.findIndex((x) => x.id === nuevoEstado.padreId)
          if (estadoPadreIndex >= 0 && this.listOrigin[estadoPadreIndex].elements.length === 1 && this.listOrigin[estadoPadreIndex].elements[0].id === nuevoEstado.id) {
            this.listOrigin.splice(estadoPadreIndex, 1)
          }
        } else {
          let estadoPadreNew = Object.assign({}, this.listOrigin.filter((x) => x.id == nuevoEstado.padreId).slice()[0])
          estadoPadreNew.elements = []
          this.listEndNormalized.push(estadoPadreNew)
          let estadoPadreIndex = this.listEndNormalized.findIndex((x) => x.id === nuevoEstado.padreId)
          this.listEndNormalized[estadoPadreIndex].elements.push(nuevoEstado)
          let estadoPadreOriginIndex = this.listOrigin.findIndex((x) => x.id === nuevoEstado.padreId)
          if (this.listOrigin[estadoPadreOriginIndex].elements.length === 1 && this.listOrigin[estadoPadreOriginIndex].elements[0].id === nuevoEstado.id) {
            this.listOrigin.splice(estadoPadreOriginIndex, 1)
          }
        }
      } else {
        let estadoPadreDest = this.listEndNormalized.filter((x) => x.id === nuevoEstado.id)
        if (estadoPadreDest.length > 0) {
          estadoPadreDest[0].elements = estadoPadreDest[0].elements.concat(nuevoEstado.elements)
        } else {
          this.listEndNormalized.push(nuevoEstado)
        }
      }
      this.listEnd = this.listEndNormalized
    } else {
      this.listEndNormalized = value
      this.listOrigin.forEach((firstChild) => {
        firstChild.elements.forEach((element) => {
          if (!element.subEstado) {
            this.listEndNormalized.push(element)
            firstChild.elements.splice(firstChild.elements.indexOf(element), 1)
          }
        })
      })
    }
    this.$emit('estadosSeleccionados', this.listEndNormalized)
  }
}
