const customFilePlugin = function (editor,url) {
    
    editor.addButton('htmlFileImport', {
        text: "Import HTML File",
        icon: 'upload',
        onclick: function() {     
            if(editor.getContent() == ""){
            editor.showFileDialog();
        }
            else {
                editor.showReplaceContentConfirmDialog()
            }
        }
    });
  editor.showInvalidHtmlFileDialod = function(){
      
        editor.windowManager.open({
        title: 'HTML inválido',
        body: [{
            type: 'container',
            items: [
                {type: 'label', text: 'El HTML debe estar entre <body></body>'}]
            }],
        buttons: [
            {
                text: 'Cerrar',
                onclick: function() {
                (this).parent().parent().close();
                }
            }]
    })
    }
    
    editor.showReplaceContentConfirmDialog = function(){
        
        editor.windowManager.open({
            
        title: 'Importar HTML',
        body: [{
            type: 'container',
            items: [
                {type: 'label', text: '¿Desea reemplazar el contenido?'}]
            }],
        buttons: [{
            text: 'Sí',
            onclick: function () {
                (this).parent().parent().close();
               editor.showFileDialog()
                          }
            },
            {
            text: 'Cerrar',
            onclick: function() {
                (this).parent().parent().close();
            }
        }]
    })
  }
  editor.showFileDialog = function(){
    
      var fileSelector = document.createElement('input');
      fileSelector.setAttribute('type', 'file');
      fileSelector.style.display = 'none';
      fileSelector.onchange = function(e) { 
        var file = fileSelector.files[0];
        if (file) {
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");

            reader.onload = function (event) {
                
                var bodyHtml = event.target.result;

                var bodyOpen = bodyHtml.indexOf('<!DOCTYPE');
                if(bodyOpen == -1)
                    bodyOpen = bodyHtml.indexOf('< !DOCTYPE');

                var bodyClose = bodyHtml.indexOf('</html>') + 6;
                if(bodyClose == -1)
                    bodyClose = bodyHtml.indexOf('</ html>') + 7;

                if(bodyOpen != -1 && bodyClose != -1){
                    bodyHtml = bodyHtml.substring(bodyOpen, bodyClose);
                    var divHtml = document.createElement('div');
                    divHtml.style.display = 'none';
                    divHtml.innerHTML = bodyHtml;
                    editor.setContent(divHtml.innerHTML);
                }
                else{
                    editor.showInvalidHtmlFileDialod();
                }
            }
            reader.onerror = function (evt) {
                editor.showInvalidHtmlFileDialod();
            }
        }
      };    
      fileSelector.click();
   }

}

export { customFilePlugin };