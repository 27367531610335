
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import ProgramacionEnviosCampana from "../../../store/entities/OZONE/programacionEnvios";
import { parseToSelect } from "@/util/selectUtil";
import { EstadoParticipacion } from "../../../store/entities/OZONE/estadoParticipacion";
import SubEstadoParticipacion from '@/store/entities/OZONE/subEstadoParticipacion';
import MetodoEnvio from '@/store/entities/OZONE/metodoEnvio'
import ServicioMensajeria from '@/store/entities/OZONE/servicioMensajeria'
import BaseFiltrableDropdown from "../../../components/Inputs/BaseFiltrableDropdown.vue";
import BaseDatePicker from "../../../components/Inputs/BaseDatePicker.vue";

@Component({
  components: { BaseFiltrableDropdown, BaseDatePicker },
})
export default class ProgramacionEnvios extends AbpBase {
  @Prop({ type: Object, default: null }) value: ProgramacionEnviosCampana;
  name: "programacion-envios";
  subEstadoParticipacionList = [];

  labelCol = { span: 4 };
  wrapperCol = { span: 14 };
  loading = false;
  activate = false;

  async created() {
    this.loading = true;
    await this.getMetodosDeEnvio()
    await this.getServiciosDeMensajeria()
    if (this.$store.state.campana.editCampana.id) {
      await this.getEstadosParticipacionDeCampana()
    }
    if(this.value && this.value.id){
      this.activate = true;
      this.getSubEstadosParticipacion();
    }
    else{
      this.activate = false;
    }   

    this.loading = false;
  }

  get metodosEnvio() {
    return this.$store.state.metodoEnvio.list;
  }

  get servicioMensajeria() {
    return this.$store.state.servicioMensajeria.list;
  }

  get estadosParticipacion() {
    return this.$store.state.estadoParticipacion.list;
  }
  get diasMaximosEnvio(){
    return this.$store.state.diasMaximosEnvio;
  }

  get camposByCampana() {
    var camposByCampana = [];

    camposByCampana = camposByCampana.concat(
      this.$store.state.campana.camposList
    );

    return camposByCampana;
  }

  get programacionEnvioRules(){
    return {
      estadosParticipacion : { filtrableDropdownRequired: false },
      subEstadosParticipacion: { filtrableDropdownRequired: false },
      campo: { filtrableDropdownRequired: false },
      fechaInicio: { required: true },
      fechaFinal: { required: true },
      diasMaximosEnvio: { required: true },
      metodosEnvio: { filtrableDropdownRequired: false },
      servicioMensajeria: { filtrableDropdownRequired: false }
    }
  }

  @Watch("value.estadoParticipacion", { deep: true })
  async onEstadoChange(estado: EstadoParticipacion) {
    if(this.value && estado){
      this.value.estadoParticipacionId = estado.id
      this.getSubEstadosParticipacion();
    }  
  }

  @Watch("value.subEstadoParticipacion", { deep: true })
  async onSubestadoChange(subEstado: SubEstadoParticipacion) {
    if(this.value && subEstado){
      this.value.subEstadoParticipacionId = subEstado.id
    }  
  }

  @Watch("value.metodoEnvio", { deep: true })
  async onMetodoEnvioChange(metodoEnvio: MetodoEnvio) {
    if(this.value && metodoEnvio){
      this.value.metodoEnvioId = metodoEnvio.id
    }  
  }

  @Watch("value.servicioMensajeria", { deep: true })
  async onServicioMensajeriaChange(servicioMensajeria: ServicioMensajeria) {
    if(this.value && servicioMensajeria){
      this.value.servicioMensajeriaId = servicioMensajeria.id
    }  
  }

  async getMetodosDeEnvio(){
    await this.$store.dispatch({
      type: "metodoEnvio/getAll",
      data: { skipCount: 0, maxResultCount: 100 },
    });
  }

  async getServiciosDeMensajeria(){
    await this.$store.dispatch({
      type: "servicioMensajeria/getAll",
      data: { skipCount: 0, maxResultCount: 100 },
    });
  }

  async getEstadosParticipacionDeCampana(){
    await this.$store.dispatch({
      type: "estadoParticipacion/getAllByCampana",
      data: { campanaId: this.$store.state.campana.editCampana.id },
    });
  }

  getSubEstadosParticipacion(){
    let estadoParticipacionId = this.value.estadoParticipacionId
    let estadoParticipacion = this.$store.state.estadoParticipacion.list.find(
      (e) => e.id == estadoParticipacionId
      );
    if (estadoParticipacionId && estadoParticipacionId !== null && estadoParticipacion) {
      this.subEstadoParticipacionList = estadoParticipacion.subEstadoParticipaciones;
    }
  }

  changeActive(value){
    if(value){
        this.$emit("input", new ProgramacionEnviosCampana());
        this.activate = true;
    } 
    else{
        this.activate = false;
        this.$emit("input", null);
    }
  }
}
