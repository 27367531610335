
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import ReglasTicketDato from '@/store/entities/OZONE/reglasTicketDato'
import DatePicker from 'vue2-datepicker'
import Campo from '@/store/entities/OZONE/campo'
import SelectCampo from '../campo/selectCampo.vue'
import Campana from '@/store/entities/OZONE/campana'
class 
ReglaTicketDatoModel extends ReglasTicketDato {
  required: boolean;
  in: string
  notin: string
  betweenDown: string
  betweenUp: string
  greater: string
  lower: string
  equal: string
  campo: Campo
}
@Component({
  components: { Modal, BaseButton, BaseCheckbox, DatePicker, SelectCampo }
})
export default class EditCampanaTicket extends AbpBase {
  name: 'edit-campana-Campo'
  selected = null
  title: String = ''

  modelTicket: ReglaTicketDatoModel[] = new Array<ReglaTicketDatoModel>()
  modelCampo: ReglaTicketDatoModel[] = new Array<ReglaTicketDatoModel>()
  inModel: string
  renderComponent = true
  campo: Campo = new Campo()
  campanaId = 0
  campana: Campana = new Campana()

  get reglasTicketList() {
    return this.$store.state.reglasTicket.list
  }
  get listPropertyType() {
    return this.$store.state.campana.listPropertyType
  }

  get camposlist() {
    return this.$store.state.grupo.camposList
  }
  log(item) {
    console.log(item)
  }
  changecheck(id: number, event) {
    this.renderComponent = false

    this.$nextTick(() => {
      this.renderComponent = true
    })
  }
  async created() {}
  async save() {}
  async beforeDestroy() {
    if (this.checkFormValidity()) {
      this.modelTicket.map((value) => {
        switch (value.reglaTicketType) {
          case 0:
            value.regla = value.in ? '{"oneOf":' + JSON.stringify(value.in.split(',')) + '}' : null
            break
          case 2:
            value.regla = value.notin ? '{"excluded":' + JSON.stringify(value.notin.split(',')) + '}' : null
            break
          case 1:
            value.regla = value.betweenDown && value.betweenUp ? '{"between":' + JSON.stringify([value.betweenDown, value.betweenUp]) + '}' : null
            break
          case 3:
            value.regla = value.greater ? '{"min_value":' + JSON.stringify(value.greater) + '}' : null
            break
          case 4:
            value.regla = value.lower ? '{"max_value":' + JSON.stringify(value.lower) + '}' : null
            break
          case 5:
            value.regla = value.equal ? '{"is":' + JSON.stringify(value.equal) + '}' : null
            break
          case 6:
            value.regla = '{"required": true}'
            break

          default:
            break
        }
      })
      this.modelCampo.map((value) => {
        
        switch (value.reglaTicketType) {
          case 0:
            value.regla = value.in ? '{"oneOf":' + JSON.stringify(value.in.split(',')) + '}' : null
            value.campoId = value.campo.id
            break
          case 2:
            value.regla = value.notin ? '{"excluded":' + JSON.stringify(value.notin.split(',')) + '}' : null
            value.campoId = value.campo.id
            break
          case 1:
            value.regla = value.betweenDown && value.betweenUp ? '{"between":' + JSON.stringify([value.betweenDown, value.betweenUp]) + '}' : null
            value.campoId = value.campo.id
            break
          case 3:
            value.regla = value.greater ? '{"min_value":' + JSON.stringify(value.greater) + '}' : null
            value.campoId = value.campo.id
            break
          case 4:
            value.regla = value.lower ? '{"max_value":' + JSON.stringify(value.lower) + '}' : null
            value.campoId = value.campo.id
            break
          case 5:
            value.regla = value.equal ? '{"is":' + JSON.stringify(value.equal) + '}' : null
            value.campoId = value.campo.id
            break
          case 6:
            value.regla = '{"required": true}'
            value.campoId = value.campo.id
            break

          default:
            break
        }
      })
      if (this.campanaId) {
        
        await this.$store.dispatch({
          type: 'campana/updateReglasTicketDato',
          data: this.modelTicket.concat(this.modelCampo)
        })
        this.resetFields()
      }
    }
  }

  async mounted() {
    this.campana = Util.extend(true, {}, this.$store.state.campana.editCampana)
    this.campanaId = this.campana.id
    await this.$store
      .dispatch({
        type: 'campana/GetAllReglasTicketForEdit',
        data: { campanaId: this.campanaId }
      })
      .then((result) => {
        
        result.map((x) => {
          let regla = new ReglaTicketDatoModel()
          regla = x
          regla.required = x.id > 0
          if (x.regla !== null) {
            let value
            try {
              value = JSON.parse(x.regla)
              switch (Object.keys(value)[0]) {
                case 'oneOf':
                  regla.in = value.oneOf.join(',')
                  regla.reglaTicketType = 0
                  break
                case 'excluded':
                  regla.notin = value.excluded.join(',')
                  regla.reglaTicketType = 2
                  break
                case 'between':
                  regla.betweenDown = value.between[0]
                  regla.betweenUp = value.between[1]
                  regla.reglaTicketType = 1
                  break
                case 'min_value':
                  regla.greater = value.min_value
                  regla.reglaTicketType = 3
                  break
                case 'max_value':
                  regla.lower = value.max_value
                  regla.reglaTicketType = 4
                  break
                case 'is':
                  regla.equal = value.is
                  regla.reglaTicketType = 5
                  break
                case 'required':
                  regla.equal = value.required
                  regla.reglaTicketType = 6
                  break

                default:
                  break
              }
            } catch {
              value = undefined
            }
          }
          if (regla.reglasTicketId != null) {
            
            this.modelTicket.push(regla)
          } else {
            regla.campo = new Campo()
            regla.campo.id = regla.campoId
            regla.campo.nombre = regla.campoNombre                   
            regla.campoId = regla.campoId
            this.modelCampo.push(regla)
          }
        })
      })
    await this.$store.dispatch({
      type: 'campana/getAllProperties',
      data: null
    })
  }

  selectCampo(selectedOption, event) {
    selectedOption.campoId = event.key
    selectedOption.campoNombre = event.label
    selectedOption.campoDescripcion = event.label;
  }
  addCampo() {
    
    let nuevaRegla = new ReglaTicketDatoModel()
    nuevaRegla.campanaId = this.campanaId
    nuevaRegla.required = true
    nuevaRegla.campoId=0
    this.modelCampo.push(nuevaRegla)
  }
  handleDeleteReglaCampo(regla, index) {
    if (regla.id) {
      this.$store
        .dispatch({
          type: 'campana/deleteReglasTicketDato',
          data: regla
        })
        .then(() => {
          this.modelCampo.splice(
            this.modelCampo.indexOf(
              this.modelCampo.find((x) => x.id == regla.id),
              1
            )
          )
        })
    } else {
      this.modelCampo.splice(this.modelCampo.indexOf(regla), 1)
    }
  }
  checkFormValidity() {
    const valid = (this.$refs.campanaReglasTicketForm as any).checkValidity()
    return valid
  }
  resetFields() {}
}
