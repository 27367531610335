<template>
  <div>
    <draggable class="dragArea" tag="collapse" :list="list" :value="value" :group="{ name: 'people' + padre, put: padre === 0 }" @change="log" @input="emitter">
      <collapse-item v-for="el in realValue" :key="el.id" :title="el.nombre" :hasChildren="el.elements && el.elements.length > 0">
        <nested :list="el.elements" :class="{ 'offset-3': el.elements && el.elements.length > 0 }" :padre="el.id" />
      </collapse-item>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import CollapseItem from './Collapse/CollapseItem.vue'
export default {
  name: 'nested',
  methods: {
    emitter(value) {
      this.$emit('input', value)
    },
    log(value) {
      this.$emit('log', value)
    }
  },
  components: {
    draggable,
    CollapseItem
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'people2',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    realValue() {
      return this.value ? this.value : this.list
    }
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    },
    padre: {
      type: Number
    }
  }
}
</script>
<style scoped>
</style>