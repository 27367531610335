
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import draggable from "vuedraggable";
import Nested from "../../../components/nested.vue";
import { maxResults } from "../../../store/entities/page-request";
import editCampanaCampo from "./edit-campana-campo.vue";

@Component({
  components: { draggable, Nested, editCampanaCampo },
})
export default class CampoDrag extends AbpBase {
  name = "campo-drag";
  @Prop(Object) value: Object | undefined;
  @Prop({ type: Number, default: 0 }) index: Number;
  
  id = 1;
  order = 1;

  listOrigin = [];
  listEnd = [];
  listEndNormalized = [];
  listOriginNormalized = [];
  
  showModalEdit = false;

  expandedKeys = [];
  autoExpandParent = true;
  checkedKeys = [];
  selectedKeys = [];
  drag = false;
  //treeData = null;

  uncheck(item) {
    console.log(item)
    var key = item.padreId + "-" + item.id;
    this.$emit("unCheck", key);
  }

  showEdit(){
    this.showModalEdit = true;
  }

  adjustTamano(newValue){
    this.value["tamanoCampo"] = newValue;
    console.log(this.value);
  }
}
