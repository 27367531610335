
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Campana from '@/store/entities/OZONE/campana'
import CuentaEmisora from '@/store/entities/OZONE/cuentaEmisora'
import PageRequest from '@/store/entities/page-request'
class PageCampanaRequest extends PageRequest {
  nombre: string
}
@Component
export default class SelectCuentaEmisora extends AbpBase {
  @Prop({ type: Object, default: null }) value: Campana
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Boolean, default: false }) required: Boolean
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  campana: CuentaEmisora = null
  name: String = 'select-cuenta-emisora'
  pagerequestCampanas: PageCampanaRequest = new PageCampanaRequest()
  maxResultCountCampana = 10
  skipCountCampana = 0
  get campanaList() {
    
    return this.$store.state.cuentaEmisora.list
  }
  get campanaCount() {
    return this.$store.state.cuentaEmisora.totalCount
  }

  async created(params: any) {
    this.pagerequestCampanas.nombre = params
    this.maxResultCountCampana = 10
    this.skipCountCampana = 0
    this.pagerequestCampanas.maxResultCount = this.maxResultCountCampana
    this.pagerequestCampanas.skipCount = this.skipCountCampana
    await this.$store.dispatch({
      type: 'cuentaEmisora/getAll',
      data: this.pagerequestCampanas
    })
  
  }

  async activated() {
    await this.getCampanasDropDown().then(() => {
      return
    })
  }


  async nextPage(offset: number) {
    this.pagerequestCampanas.skipCount = offset
    await this.$store.dispatch({
      type: 'cuentaEmisora/getAll',
      data: this.pagerequestCampanas
    })
  }

  async getCampanasDropDown() {
    
    this.pagerequestCampanas.maxResultCount = this.maxResultCountCampana
    this.pagerequestCampanas.skipCount = this.skipCountCampana
    await this.$store.dispatch({
      type: 'cuentaEmisora/getAll',
      data: this.pagerequestCampanas
    })
  }

  campanaRule = {
    campana: {
      // required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Campana')),
      placeholder: this.L('Campana'),
      trigger: 'blur'
    }
  }
}
