
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import draggable from "vuedraggable";
import Nested from "../../../components/nested.vue";
import { maxResults } from "../../../store/entities/page-request";
import SelectCuentaEmisora from "../cuentaEmisora/select-cuenta-emisora.vue";
import PageRequest from "../../../store/entities/page-request";
import moment, { Moment } from "moment";
import { email } from "vee-validate/dist/rules";
import ProgramacionTransferenciaCampana from "../../../store/entities/OZONE/programacionTransferencia";
import { EstadoParticipacion } from "../../../store/entities/OZONE/estadoParticipacion";
import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";
import Campo from "../../../store/entities/OZONE/campo";
import TipoFormaPagoCashback from "../../../store/entities/OZONE/tipoFormaPagoCashback";

@Component({
  components: { draggable, Nested, SelectCuentaEmisora },
})
export default class ProgramacionTransferencia extends AbpBase {
  @Prop({ type: Object, default: null })
  value: ProgramacionTransferenciaCampana;
  name: "programacion-transferencia";
  id = 0;
  frequency = "W";
  laborales = false;
  loading = false;
  creating = false;
  pagerequestCampanas: PageRequest = new PageRequest();
  programacion: ProgramacionTransferenciaCampana;
  subEstadoParticipacionList: Array<SubEstadoParticipacion> = [];
  form: any;
  dowFrequency = [];
  formItemLayout: any = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  formItemLayoutWithOutLabel: any = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  doW = [
    { label: "Lunes", value: "1" },
    { label: "Martes", value: "2" },
    { label: "Miércoles", value: "3" },
    { label: "Jueves", value: "4" },
    { label: "Viernes", value: "5" },
    { label: "Sábado", value: "6" },
    { label: "Domingo", value: "7" },
  ];

  frequencyDefinition: {
    active: boolean;
    estado: EstadoParticipacion;
    subestado: SubEstadoParticipacion;
    campo: Campo;
    type: string;
    freq: number;
    labour: boolean;
    month: number;
    day: number;
    days: Array<string>;
    range: Array<Moment>;
    emails: Array<string>;
    account: number;
    terminalBizum:number;
    po: string;
    motivo: string;
    formaPago: number;
    limitePorTransferencia:number;
  } = {
    active: false,
    estado: new EstadoParticipacion(),
    subestado: new SubEstadoParticipacion(),
    campo: new Campo(),
    type: "D",
    freq: 1,
    month: 1,
    labour: false,
    day: 1,
    days: ["2", "4"],
    range: null,
    po: null,
    account: null,
    terminalBizum:null,
    motivo: null,
    emails: [],
    formaPago:0,
    limitePorTransferencia:null
  };

  frequencyToken() {
    alert(this.frequencyDefinition.emails);
    let type = this.frequencyDefinition.type;
    let period = `${this.frequencyDefinition.freq}x`;
    if (type == "D") {
      period += this.frequencyDefinition.labour ? "1,2,3,4,5" : "1,2,3,4,5,6,7";
    } else if (type == "W") {
      period += this.frequencyDefinition.days.join(",");
    } else if (type == "M") {
      period += this.frequencyDefinition.day;
    } else if (type == "Y") {
      period += this.frequencyDefinition.month;
      period += "x";
      period += this.frequencyDefinition.day;
    }

    console.log({ type: type, period: period });
  }

  get cuentasEmisoras() {
    let cuentas = this.$store.state.cuentaEmisora.list.map((e) => {
      return {
        label: e.nombre + " ( " + e.numero + " )",
        value: e.id,
      };
    });
    return cuentas;
  }

  get terminalesBizum() {
    let cuentas = this.$store.state.terminalBizum.list.map((e) => {
      return {
        label: e.merchantCode + " - " + e.terminal ,
        value: e.id,
      };
    });
    return cuentas;
  }
  
  
  get tiposDePago() {
    let cuentas = this.$store.state.tipoFormaPagoCashback.list.map((e) => {
      return {
        label: e.nombre,
        value: e.id,
      };
    });
    return cuentas;
  }
  

  @Watch("frequencyDefinition", { deep: true })
  parseToProgramacion() {
    if (!this.frequencyDefinition.active) {
      this.$emit("input", null);
      return;
    }
    let programacion = this.programacion;
    programacion.cuentasEmisorasId = this.frequencyDefinition.account;
    programacion.motivo = this.frequencyDefinition.motivo;
    programacion.destinatarios = this.frequencyDefinition.emails.join();
    programacion.formaPago = this.frequencyDefinition.formaPago;
    programacion.limitePorTransferencia=this.frequencyDefinition.limitePorTransferencia;
    let frecuencia = "";

    programacion.fechaInicio =
      this.frequencyDefinition.range &&
      this.frequencyDefinition.range.length > 0
        ? this.frequencyDefinition.range[0].toDate()
        : null;
    programacion.fechaFin =
      this.frequencyDefinition.range &&
      this.frequencyDefinition.range.length > 1
        ? this.frequencyDefinition.range[1].toDate()
        : null;

    programacion.tipoFrecuencia = this.frequencyDefinition.type;
    programacion.ordenCompra = this.frequencyDefinition.po;
    programacion.formaPago = this.frequencyDefinition.formaPago;
    programacion.terminalBizumId = this.frequencyDefinition.terminalBizum;
    programacion.limitePorTransferencia = this.frequencyDefinition.limitePorTransferencia;

    if (this.frequencyDefinition.type == "D") {
      frecuencia =
        this.frequencyDefinition.freq +
        "x" +
       ( this.frequencyDefinition.labour == true?'1,2,3,4,5':'1,2,3,4,5,6,7');
    } else if (this.frequencyDefinition.type == "W") {
      frecuencia =
        this.frequencyDefinition.freq +
        "x" +
        this.frequencyDefinition.days ? this.frequencyDefinition.days.join(', ') : '';
    } else if (this.frequencyDefinition.type == "M") {
      frecuencia =
        this.frequencyDefinition.freq + "x" + this.frequencyDefinition.day;
    } else if (this.frequencyDefinition.type == "Y") {
      frecuencia =
        this.frequencyDefinition.freq+
        "x" +
        this.frequencyDefinition.month +
        "x" +
        this.frequencyDefinition.day  ;
    }

    programacion.frecuencia = frecuencia;

    programacion.campo = this.frequencyDefinition.campo;
    programacion.estadoParticipacion = this.frequencyDefinition.estado;
    programacion.subEstadoParticipacion = this.frequencyDefinition.subestado;

    this.$emit("input", programacion);
  }

  remove(k, index) {
    const { form } = this;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
    this.frequencyDefinition.emails.splice(index, 1);
  }

  add() {
    const { form } = this;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(this.id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        console.log("Received values of form: ", values);
        console.log(
          "Merged values:",
          keys.map((key) => names[key])
        );
      }
    });
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  async created() {
    this.creating = true;
    this.subEstadoParticipacionList = [];
    this.programacion = this.value
      ? this.value
      : new ProgramacionTransferenciaCampana();

    this.form = this.$form.createForm(this, { name: "dynamic_form_item" });
    this.form.getFieldDecorator("keys", {
      initialValue: this.programacion.destinatarios
        ? this.programacion.destinatarios.split(",")
        : [],
      preserve: true,
    });
    await this.getEstadoParticipacion();

    this.pagerequestCampanas.maxResultCount = 100;
    this.pagerequestCampanas.skipCount = 0;

    await this.$store.dispatch({
      type: "cuentaEmisora/getAll",
      data: this.pagerequestCampanas,
    });
    await this.$store.dispatch({
      type: "terminalBizum/getAll",
      data: this.pagerequestCampanas,
    });
    await this.$store.dispatch({
      type: "tipoFormaPagoCashback/getAll",
      data: this.pagerequestCampanas,
    });
    if (this.programacion.id) {
      var values = this.programacion.frecuencia.split("x");
      let day, days, year, freq, month;
      freq = values[0];

      if (this.programacion.tipoFrecuencia == "W") {
        days = values.length==1 ? 0 : values[1].split(",");
      } else if (this.programacion.tipoFrecuencia == "M") {
        days = values.length==1 ? 0 : values[1];
       } else if (this.programacion.tipoFrecuencia == "Y") {
        day = values.length==3 ? 0 : values[2];
        month =  values.length==2 ? 0 : values[1];
      }

      this.frequencyDefinition = {
        active: true,
        type: this.programacion.tipoFrecuencia,
        estado: this.programacion.estadoParticipacion,
        subestado: this.programacion.subEstadoParticipacion,
        campo: this.programacion.campo,
        freq: freq,
        month: month,
        labour: values[1] == "1,2,3,4,5",
        day: day,
        days: days,
        range: [
          moment(this.programacion.fechaInicio),
          moment(this.programacion.fechaFin),
        ],
        account: this.programacion.cuentasEmisorasId,
        motivo: this.programacion.motivo,
        po: this.programacion.ordenCompra,
        emails: this.programacion.destinatarios.split(","),
        formaPago: this.programacion.formaPago,
        limitePorTransferencia:this.programacion.limitePorTransferencia,
        terminalBizum:this.programacion.terminalBizumId
      };
    } else {
      this.frequencyDefinition = {
        active: false,
        type: "D",
        estado: new EstadoParticipacion(),
        subestado: new SubEstadoParticipacion(),
        campo: new Campo(),
        freq: 1,
        month: 1,
        labour: false,
        day: 1,
        days: ["2", "4"],
        range: null,
        account: null,
        motivo: null,
        po: "",
        emails: [],
        formaPago:0,
        limitePorTransferencia:0,
        terminalBizum:null
      };

      this.programacion.campanaId = this.$store.state.campana.editCampana.id;
    }

    this.creating = false;
  }

  get estadoParticipacionList() {
    return this.$store.state.estadoParticipacion.list;
  }

  @Watch("frequencyDefinition.estado", { deep: true })
  async selectEstadoParticipacionChange() {
    var value = this.frequencyDefinition.estado;

    var originValue = this.estadoParticipacionList.find(
      (e) => e.id == value.id
    );

    if (value && value !== null && originValue) {
      this.subEstadoParticipacionList = originValue.subEstadoParticipaciones;
    }
  }

  async getEstadoParticipacion() {
    this.loading = true;
    if (this.$store.state.campana.editCampana.id) {
      await this.$store.dispatch({
        type: "estadoParticipacion/getAllByCampana",
        data: { campanaId: this.$store.state.campana.editCampana.id },
      });
    }
    this.loading = false;
  }

  get camposByCampana() {
    var camposByCampana = [];

    camposByCampana = camposByCampana.concat(
      this.$store.state.campana.camposList
    );

    return camposByCampana;
  }
}
