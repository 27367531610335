
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import CreateDocumento from "./create-documento.vue";
import Campana from "../../../store/entities/OZONE/campana";

class PageEmailRequest extends PageRequest {
  filter: string;
  campanaId: number;
}

@Component({
  components: {
    CreateDocumento,
  },
})
export default class Documentos extends AbpBase {
  //filters
  pagerequest: PageEmailRequest = new PageEmailRequest();
  creationTime: Date[] = [];
  pagination: any = {};
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  title: String = "";
  campanaId = 0;
  campana: Campana = new Campana();

  get list() {
    return this.$store.state.campana.documentos;
    ;
  }
  get loading() {
    return this.$store.state.campana.loading;
  }

  create() {
    this.createModalShow = true;
  }


  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar este documento?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "campana/deleteDocumentoLegal",
            data: item,
          });
          await this.getpage();
        }
      });
  }


  async getpage() {
    this.campana = Util.extend(true, {}, this.$store.state.campana.editCampana);
    this.campanaId = this.campana.id;
    this.pagerequest.maxResultCount = 50;
    this.pagerequest.skipCount = 0;
    this.pagerequest.campanaId = this.$store.state.campana.editCampana.id;

    await this.$store.dispatch({
      type: "campana/getAllDocumentos",
      data: this.pagerequest,
    });
  }


  columns = [
    {
      title: this.L("Tipo"),
      dataIndex: "tipo",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Fichero"),
      dataIndex: "fichero",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Url Público"),
      dataIndex: "urlPublico",
      scopedSlots: { customRender: "link" },
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Visitas"),
      dataIndex: "vistas",
      scopedSlots: { customRender: "status" },
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];

  async created() {
    this.getpage();
  }
}
