
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import Campo from '@/store/entities/OZONE/campo'
import Campana from '@/store/entities/OZONE/campana'
@Component({
  components: { Modal, BaseButton, BaseCheckbox }
})
export default class EditCampanaCampo extends AbpBase {
  name: 'edit-campana-Campo'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Number, default: 0 }) tamanoCampo: number
  @Prop({ type: Boolean, default: false }) editing: boolean
  campo: Campo = new Campo()
  campana: Campana = new Campana()
  title: String = this.L('Editar tamaño del campo');
  subTitle: String = this.L('Ajusta el tamaño del campo para los formularios de grabación');
  tamanoCampoNew = 0;

  get formatedCampoValue(){
    return this.formatCampoValueToSliderValue(this.tamanoCampoNew);
  }
  set formatedCampoValue(newValue){
    this.tamanoCampoNew = this.formatSliderValueToCampoValue(newValue);
  }

  get marks(){
    return {
      0: 'Corto',
      25: "",
      50: "",
      75: "",
      100: "Largo"
    }
  }

  created() {}

  async save() {
    this.$emit("tamano-cambiado", this.tamanoCampoNew);
    this.close();
  }

  formatCampoValueToSliderValue(campoValue){

    let campoToSlider = 0;
    switch(campoValue){
      case 6:
        campoToSlider = 0;
        break;
      case 8:
        campoToSlider = 25;
        break;
      case 12:
        campoToSlider = 50;
        break;
      case 18:
        campoToSlider = 75;
        break;
      case 24:
        campoToSlider = 100;
        break;
      default:
        campoToSlider = 100;
        break;
    }

    return campoToSlider;
  }

  formatSliderValueToCampoValue(sliderValue){

    let sliderToCampo = 24;
    switch(sliderValue){
      case 0:
        sliderToCampo = 6;
        break;
      case 25:
        sliderToCampo = 8;
        break;
      case 50:
        sliderToCampo = 12;
        break;
      case 75:
        sliderToCampo = 18;
        break;
      case 100:
        sliderToCampo = 24;
        break;
      default:
        sliderToCampo = 24;
        break;
    }
    return sliderToCampo;
  }

  close() {
    this.$emit('input', false)
    this.resetFields()
  }

  shown() {
    this.tamanoCampoNew = this.tamanoCampo;
  }

  checkFormValidity() {
    const valid = (this.$refs.campanaCampoForm as any).checkValidity()
    return valid
  }

  resetFields() {
    this.campo = new Campo();
    this.tamanoCampoNew = 0;
  }

  formatter(value) {
    return `${value}%`;
  }

  tableRule = {
    name: {
      required: { required: true, min: 2, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Name')),
      placeholder: this.L('Name'),
      trigger: 'blur'
    },
    identificador: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Identificador')),
      placeholder: this.L('Identificador'),
      trigger: 'blur',
      type: 'number'
    }
  }
}
