
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import BaseCheckbox from "../../../components/Inputs/BaseCheckbox.vue";
import EmailEstadoParticipacion from "../../../store/entities/OZONE/emailEstadoParticipacion";
import { EstadoParticipacion } from "../../../store/entities/OZONE/estadoParticipacion";
import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import tinymce from "vue-tinymce-editor";
import editorApp from "../../../components/editor/tinymce.vue";
import DocumentoLegal from "../../../store/entities/OZONE/documentoLegal";

class EstadosRequest extends PageRequest {
  campanaId: number;
}
class PageCamposRequest extends PageRequest {
  campanaId: number;
}
@Component({
  components: { Modal, BaseButton, BaseCheckbox, tinymce, editorApp },
})
export default class CreateDocumento extends AbpBase {
  name: "create-documento";
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Boolean, default: false }) editing: boolean;
  @Prop({ type: String }) id: string;
  loading: boolean = false;
  tipo: { nombre: string } = null;
  url: string = "";
  fichero: string = "";
  title: string = "";
  data: string = "";
  fileList = [];
  readContentFile: string;

  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }

  beforeUpload(file) {
    this.fileList = [file];
    return false;
  }

  tipos = [
    { nombre: "Bases Legales" },
    { nombre: "FAQs" },
    { nombre: "Términos de Uso" },
  ];

  created() {}

  async save() {
    if (this.checkFormValidity()) {
      this.loading = true;
      if (this.fileList && this.fileList.length > 0) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.readContentFile = e.target.result as string;
        };
        reader.onloadend = async (e) => {
          var documento = new DocumentoLegal();

          documento.campanaId = this.$store.state.campana.editCampana.id;
          documento.urlPublico = this.url;
          documento.tipo = this.tipo.nombre;
          documento.fichero = this.url.split('/')[this.url.split('/').length - 1];
          documento.vistas = 0;
          documento.ficheroContent = this.readContentFile;
          console.log(documento.ficheroContent);

          await this.$store.dispatch({
            type: "campana/updateDocumentoLegal",
            data: documento,
          });

          this.loading = false;
          this.$emit("save-success");
          this.$emit("input", false);
          this.resetFields();
        };
        reader.readAsDataURL(this.fileList[0]);
      }
    }
  }

  close() {
    this.$emit("input", false);
    this.resetFields();
  }

  shown() {
    this.data = "";
    if (this.editing) {
      this.title = "Editar Documento";
    } else {
      this.title = "Crear Documento";
    }
  }

  checkFormValidity() {
    const valid = (this.$refs.emailForm as any).checkValidity();
    return valid;
  }

  resetFields() {
    this.tipo = null;
  }
}
