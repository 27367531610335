import { customFilePlugin } from '../plugins/fileToolbarButton/myFileToolbarButton.js';
import { cuPlugin } from '../plugins/myCustomToolbarButton/plugin.js';

var config = {
    select: "#app-editor",
    
    plugins: [
           'autoresize myCustomToolbarButton htmlFileImport advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
    toolbar1:
           'myCustomToolbarButton | htmlFileImport | undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | styleselect,formatselect,fontselect,fontsizeselect | \
           bullist numlist outdent indent | removeformat | help',
    language: 'es',
    width: '100%',
  height: 400,
  autoresize_min_height: 400,
  autoresize_max_height: 800,
    setup: function () {
        // Here we can add plugin 
        window.tinymce.PluginManager.add('myCustomToolbarButton', cuPlugin);
        window.tinymce.PluginManager.add('htmlFileImport', customFilePlugin);

        
    },
    campos: [],
    font_formats: "Andale Mono=andale mono,times;"+
        "Arial=arial,helvetica,sans-serif;"+
        "Arial Black=arial black,avant garde;"+
        "Book Antiqua=book antiqua,palatino;"+
        "Comic Sans MS=comic sans ms,sans-serif;"+
        "Courier New=courier new,courier;"+
        "Georgia=georgia,palatino;"+
        "Helvetica=helvetica;"+
        "Impact=impact,chicago;"+
        "Symbol=symbol;"+
        "Tahoma=tahoma,arial,helvetica,sans-serif;"+
        "Terminal=terminal,monaco;"+
        "Times New Roman=times new roman,times;"+
        "Trebuchet MS=trebuchet ms,geneva;"+
        "Verdana=verdana,geneva;"+
        "Webdings=webdings;"+
        "Wingdings=wingdings,zapf dingbats",
    // font_formats:
    // "Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;"



}

export default { config } ;