<template>
  <b-card no-body>
    <b-card-body role="tab" :aria-expanded="active">
      <a data-toggle="collapse" data-parent="#accordion" :href="`#${itemId}`" @click.prevent="activate" :aria-controls="`content-${itemId}`" :class="{ collapsed: !active }" class="list-group-collapse">
        <slot name="title"> {{ title }} </slot>
        <div v-if="hasChildren" class="collapse-arrow"><i class="fas fa-angle-down float-right"></i></div>
      </a>
    </b-card-body>
    <collapse-transition :duration="animationDuration">
      <div v-show="active || !hasChildren" :id="`content-${itemId}`" role="tabpanel" :aria-labelledby="title" class="collapse show">
        <slot></slot>
      </div>
    </collapse-transition>
  </b-card>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions'

export default {
  name: 'collapse-item',
  components: {
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: '',
      description: 'Collapse item title'
    },
    id: String,
    hasChildren: Boolean
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: () => {}
    },
    removeItem: {
      default: () => {}
    },
    deactivateAll: {
      default: () => {}
    }
  },
  computed: {
    itemId() {
      return this.id || this.title
    }
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    activate() {
      let wasActive = this.active

      this.active = !wasActive
    }
  },
  mounted() {
    this.addItem(this)
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.removeItem(this)
  }
}
</script>
<style>
.collapse-arrow {
  display: inline-block;
  margin-left: auto;
  margin-right: 0rem;
  transition: transform 0.15s ease;
}
.collapsed .collapse-arrow {
  transform: rotate(-90deg);
}

.list-group-collapse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
</style>
