<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="8">
          <b-form-checkbox :value="value" :disabled="disabled" v-model="selected">
            <slot>
              <span v-if="inline" data-toggle="tooltip" :title="description">{{ label != '_length' ? label : 'length' }}--{{ description }}</span>
            </slot>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-row v-if="value === 'between' && between">
          <b-col>
            <base-input :rules="{ required: between }" type="number" id="btmin" name="Min" label="Min" placeholder="Min" v-model="btmin" ref="betweenMin" refs="betweenInputMin" @change="changed" :disabled="!between"></base-input>
          </b-col>
          <b-col>
            <base-input :rules="{ required: between, min_value: btmin }" type="number" id="btmax" name="Max" label="Max" placeholder="Max" v-model="btmax" ref="betweenMax" refs="betweenInputMax" @change="changed" :disabled="!between"></base-input>
          </b-col>
        </b-row>
        <b-row v-if="value === 'digits' && digits">
          <b-col>
            <base-input :rules="{ required: digits }" type="number" id="dgnumber" name="Number" label="Número de dígitos" placeholder="Número de dígitos" v-model="inputDigits" @change="changedDigits" :disabled="!digits"></base-input>
          </b-col>
        </b-row>
        <b-row v-if="value === 'length' && length">
          <b-col>
            <base-input :rules="{ required: length }" type="number" id="lgnumber" name="Number" label="Longitud" placeholder="Longitud" v-model="inputLength" @change="changedLength" :disabled="!length"></base-input>
          </b-col>
        </b-row>
        <b-row v-if="value === 'max' && max">
          <b-col>
            <base-input :rules="{ required: max }" type="number" id="maxnumber" name="Number" label="Máxima longitud" placeholder="Máxima longitud" v-model="inputMax" @change="changedMax" :disabled="!max"></base-input>
          </b-col>
        </b-row>
        <b-row v-if="value === 'min' && min">
          <b-col>
            <base-input :rules="{ required: min }" type="number" id="minnumber" name="Number" label="Mínima longitud" placeholder="Mínima longitud" v-model="inputMin" @change="changedMin" :disabled="!min"></base-input>
          </b-col>
        </b-row>
        <b-row v-if="value === 'regex' && regex">
          <b-col>
            <base-input :rules="{ required: regex }" id="regex" name="regex" label="Expresión regular" placeholder="Expresión regular" v-model="inputRegex" @change="changedRegex" :disabled="!regex"></base-input>
          </b-col>
        </b-row>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { extend } from 'vee-validate'
import moment from 'moment'
// extend('between', {
//   params: ['min', 'max'],
//   validate(value, { min, max }) {
//     if (moment(value, 'DD/MM/YYYY,true').isValid()) {
//       return moment(value, 'DD/MM/YYYY,true') >= moment(min, 'DD/MM/YYYY,true') && moment(value, 'DD/MM/YYYY,true') <= moment(max, 'DD/MM/YYYY,true')
//     } else {
//       
//       return parseInt(value) >= parseInt(min) && parseInt(value) <= parseInt(max)
//     }
//   },
//   message: 'Este campo debe estar entre {min} y {max}'
// }),
//   extend('date_format', {
//     validate: (value) => {
//       
//       return moment(value, 'DD/MM/YYYY,true').isValid()
//     },
//     message: 'Este campo debe ser una fecha válida'
//   })
export default {
  name: 'rule-checkbox',
  data() {
    return {
      inputValues: null,
      between: false,
      btmin: null,
      btmax: null,
      digits: false,
      inputDigits: null,
      length: false,
      inputLength: null,
      max: false,
      inputMax: null,
      min: false,
      inputMin: null,
      regex: false,
      inputRegex: null,
      dateFormat: false
    }
  },

  props: {
    value: {
      type: String,
      description: 'the value'
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled'
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline'
    },
    label: {
      type: String,
      description: 'Label'
    },
    description: {
      type: String,
      description: 'description'
    },
    selected: {
      type: Array,
      default: function () {
        return []
      }
    },
    values: {
      type: [String, Number, Array]
    }
  },

  created() {},

  mounted() {
    this.inputValues = this.values
    this.watchBoolean(this.selected, [], 'alpha')
    this.watchBoolean(this.selected, [], 'alpha_dash')
    this.watchBoolean(this.selected, [], 'alpha_num')
    this.watchBoolean(this.selected, [], 'alpha_spaces')
    this.watchBetween(this.selected, [])
    this.watchDigits(this.selected, [])
    this.watchBoolean(this.selected, [], 'email')
    this.watchBoolean(this.selected, [], 'integer')
    this.watchLength(this.selected, [])
    this.watchMax(this.selected, [])
    this.watchMin(this.selected, [])
    this.watchBoolean(this.selected, [], 'numeric')
    this.watchRegex(this.selected, [])
    this.watchBoolean(this.selected, [], 'required')
    this.watchBoolean(this.selected, [], 'double')
    this.watchBoolean(this.selected, [], 'date_fotmat')
  },

  watch: {
    selected(newValue, oldValue) {
      this.watchBoolean(newValue, oldValue, 'alpha')
      this.watchBoolean(newValue, oldValue, 'alpha_dash')
      this.watchBoolean(newValue, oldValue, 'alpha_num')
      this.watchBoolean(newValue, oldValue, 'alpha_spaces')
      this.watchBetween(newValue, oldValue)
      this.watchDigits(newValue, oldValue)
      this.watchBoolean(newValue, oldValue, 'email')
      this.watchBoolean(newValue, oldValue, 'integer')
      this.watchLength(newValue, oldValue)
      this.watchMax(newValue, oldValue)
      this.watchMin(newValue, oldValue)
      this.watchBoolean(newValue, oldValue, 'numeric')
      this.watchRegex(newValue, oldValue)
      this.watchBoolean(newValue, oldValue, 'required')
      this.watchBoolean(newValue, oldValue, 'double')
      this.watchBoolean(newValue, oldValue, 'date_format')
    }
  },
  methods: {
    changed() {
      if (this.btmin && this.btmax) {
        this.$emit('between', this.between, this.btmin, this.btmax)
      }
    },
    changedDigits() {
      if (this.inputDigits) {
        this.$emit('digits', this.digits, this.inputDigits)
      }
    },
    changedLength() {
      if (this.inputLength) {
        this.$emit('length', this.length, this.inputLength)
      }
    },
    changedMax() {
      if (this.inputMax) {
        this.$emit('max', this.max, this.inputMax)
      }
    },
    changedMin() {
      if (this.inputMin) {
        this.$emit('min', this.min, this.inputMin)
      }
    },
    changedRegex() {
      if (this.inputRegex) {
        this.$emit('regex', this.regex, this.inputRegex)
      }
    },
    watchBoolean(newValue, oldValue, key) {
      if (this.value === key) {
        if (oldValue != null && !oldValue.find((x) => x === key)) {
          if (newValue !== null && newValue.find((x) => x === key)) {
            this.$emit(key, true)
          }
        }
        if (oldValue != null && oldValue.find((x) => x === key)) {
          if (newValue !== null && !newValue.find((x) => x === key)) {
            this.$emit(key, false)
          }
        }
      }
    },
    watchDigits(newValue, oldValue) {
      const key = 'digits'
      const rule = this.inputValues.find((x) => Object.keys(x) == key)
      if (rule) {
        this.inputDigits = rule.digits
      }
      if (this.value === key) {
        if (oldValue != null && !oldValue.find((x) => x === key)) {
          if (newValue !== null && newValue.find((x) => x === key)) {
            this.digits = true
            if (this.inputDigits) {
              this.$emit(key, this.digits, this.inputDigits)
            }
          }
        }
        if (oldValue != null && oldValue.find((x) => x === key)) {
          if (newValue !== null && !newValue.find((x) => x === key)) {
            this.digits = false
            this.$emit(key, this.digits, null)
          }
        }
      }
    },
    watchBetween(newValue, oldValue) {
      const key = 'between'
      const btRule = this.inputValues.find((x) => Object.keys(x) == key)
      if (btRule) {
        this.btmin = btRule.between.min
        this.btmax = btRule.between.max
      }
      if (this.value === key) {
        //Si no se ha seleccionado la opción se retorna
        if (!oldValue.find((x) => x === key) && !newValue.find((x) => x === key)) {
          return
        }

        if (!oldValue.find((x) => x === key)) {
          if (newValue.find((x) => x === key)) {
            //Si se añade
            this.between = true
            if (this.$refs.betweenMin !== undefined) {
              this.$refs.betweenMin.$refs.betweenInputMin.disabled = false
              this.$refs.betweenMax.$refs.betweenInputMax.disabled = false
              this.$refs.betweenMin.$refs.betweenInputMin.focus()
              this.$refs.betweenMax.$refs.betweenInputMax.focus()
              this.$refs.betweenMin.$refs.betweenInputMin.focus()
            }
            if (this.btmin && this.btmax) {
              this.$emit(key, this.between, this.btmin, this.btmax)
            }
          }
        } else {
          //Si se quita
          if (oldValue.find((x) => x === key)) {
            if (!newValue.find((x) => x === key)) {
              this.between = false
              if (this.$refs.betweenMin !== undefined) {
                this.$refs.betweenMin.$refs.betweenInputMin.disabled = true
                this.$refs.betweenMax.$refs.betweenInputMax.disabled = true
              }
              this.$emit(key, this.between, null, null)
            }
          }
        }
      }
    },
    watchLength(newValue, oldValue) {
      const key = 'length'
      const rule = this.inputValues.find((x) => Object.keys(x) == key)
      if (rule) {
        this.inputLength = rule.length
      }
      if (this.value === key) {
        if (oldValue != null && !oldValue.find((x) => x === key)) {
          if (newValue !== null && newValue.find((x) => x === key)) {
            this.length = true
            if (this.inputLength) {
              this.$emit('length', this.length, this.inputLength)
            }
          }
        }
        if (oldValue != null && oldValue.find((x) => x === key)) {
          if (newValue !== null && !newValue.find((x) => x === key)) {
            this.length = false
            this.$emit('length', this.length, null)
          }
        }
      }
    },
    watchMax(newValue, oldValue) {
      const key = 'max'
      const rule = this.inputValues.find((x) => Object.keys(x) == key)
      if (rule) {
        this.inputMax = rule.max
      }
      if (this.value === key) {
        if (oldValue != null && !oldValue.find((x) => x === key)) {
          if (newValue !== null && newValue.find((x) => x === key)) {
            this.max = true
            if (this.inputMax) {
              this.$emit(key, this.max, this.inputMax)
            }
          }
        }
        if (oldValue != null && oldValue.find((x) => x === key)) {
          if (newValue !== null && !newValue.find((x) => x === key)) {
            this.max = false
            this.$emit(key, this.max, null)
          }
        }
      }
    },
    watchMin(newValue, oldValue) {
      const key = 'min'
      const rule = this.inputValues.find((x) => Object.keys(x) == key)
      if (rule) {
        this.inputMin = rule.min
      }
      if (this.value === key) {
        if (oldValue != null && !oldValue.find((x) => x === key)) {
          if (newValue !== null && newValue.find((x) => x === key)) {
            this.min = true
            if (this.inputMin) {
              this.$emit(key, this.min, this.inputMin)
            }
          }
        }
        if (oldValue != null && oldValue.find((x) => x === key)) {
          if (newValue !== null && !newValue.find((x) => x === key)) {
            this.min = false
            this.$emit(key, this.min, null)
          }
        }
      }
    },
    watchRegex(newValue, oldValue) {
      const key = 'regex'
      const rule = this.inputValues.find((x) => Object.keys(x) == key)
      if (rule) {
        this.inputRegex = rule.regex
      }
      if (this.value === key) {
        if (oldValue != null && !oldValue.find((x) => x === key)) {
          if (newValue !== null && newValue.find((x) => x === key)) {
            this.regex = true
            if (this.inputRegex) {
              this.$emit(key, this.regex, this.inputRegex)
            }
          }
        }
        if (oldValue != null && oldValue.find((x) => x === key)) {
          if (newValue !== null && !newValue.find((x) => x === key)) {
            this.regex = false
            this.$emit(key, this.regex, null)
          }
        }
      }
    }
  }
}
</script>
