const cuPlugin = function(editor){
  editor.addButton('myCustomToolbarButton', {
    text: 'Añadir Campo',
    icon:'user',
    onclick: () => {
      _onAction(editor.settings.campos)
    }
  });
  function _onAction(campos)
  {
    var listValues = campos.map((x) => { return { text: x.nombre, value: x.nombre } })
    editor.windowManager.open({
      title: 'Insertar Campo',
      width: 320,
      height: 100,
      body: [
       
        {type: 'listbox', name: 'campos', label: 'Seleccione campo', 'values': listValues}
    ],
        
        onSubmit: function (api) {
            editor.insertContent('[' + api.data.campos + ']');
        },
    });
}
}

export { cuPlugin };