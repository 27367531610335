
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Campana from "../../../store/entities/OZONE/campana";
import editEan from "./edit-ean.vue";
import XLSX from "xlsx";
import PageRequest, { maxResults } from "../../../store/entities/page-request";

class PageEanRequest extends PageRequest {
  campanaId: number;
}

@Component({
  components: {
    editEan,
  },
})
export default class Ean extends AbpBase {
  downloadUrl: `$ {process.env.BASE_URL} fileResources / template.xlsx`;
  //filters
  @Prop({ type: Array }) eans: Array<any>;
  file: File = null;
  pagerequest: PageEanRequest = new PageEanRequest();
  creationTime: Date[] = [];
  campana: Campana = new Campana();
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  listNewEans: any = [];
  eansByCampana: Array<any> = [];
  listEans: Array<any> = [];
  pagination: any = {};
  error: string = "";
  mostrarError:boolean=false
  loadingEans: boolean = false;
  
  get list() {
    return this.$store.state.ean.list;
    // return this.$store.state.campana.eansList
  }

  get list_eans() {
    return this.$store.state.campana.eansList;
  }

  get loading() {
    this.mostrarError=false;
    return this.$store.state.ean.loading;
  }

  async create() {
    this.createModalShow = true;
    this.pagerequest = new PageEanRequest();
  }

  editRow(item, index, button) {
    this.$store.commit("ean/edit", item);
    this.edit();
    this.getEansByCampana();
  }

  uploadEans() {
    this.mostrarError=false;
    if (this.file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        //INSERCIÓN EAN EXCEL
        //Falta por añadir:
        // - Si un registro tiene algún fallo de validación, sale del bucle y no realiza la inserción
        // - Ver si los campos -importe- y -importe porcentual- son obligatorios o pueden entrar vacios
        // - Mostrar mensaje de error si fallase validación

        const dataExcel = XLSX.utils.sheet_to_json(ws, { header: 2 });
        var ok = true;
        dataExcel.forEach((value, index) => {
          dataExcel[index]["CampanaId"] = this.$store.state.campana.editCampana.id;
          if (dataExcel[index]["Cod_Ean"].toString().length == 13) {
            if (
              !dataExcel[index]["Titulo"] ||
              !dataExcel[index]["Marca"] ||
              !dataExcel[index]["Categoria"]
            ) {
              ok = false;
              this.error="Los campos Titulo, Marca y Categoría al cargar los EANS son obligatorios."
              this.mostrarError=true;
           
            }
          } else {
            ok = false;
            this.mostrarError=true;
            this.error="Codigo EAN en el registro " + index + " no tiene 13 caracteres."
           
            console.error(
              "Codigo EAN en el registro " + index + " no tiene 13 caracteres."
            );
          }
          if (ok) {
            this.mostrarError=false;
            console.log(JSON.stringify(value));
            this.listNewEans.push(value);
          }
          console.log(this.listNewEans);
        });

        this.leerExcell();
      };

      reader.readAsBinaryString(this.file);
    }
  }

  async leerExcell() {
   
    let item = this.listNewEans;
    await this.$store.dispatch({
      type: "ean/leerExcell",
      data: item,
    });
    this.getEansByCampana();
    this.file = null;
    this.listNewEans = [];
  }

  deleteAllEans(){
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar todos los EAN de la campaña?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          this.loadingEans = true;
          await this.$store.dispatch({
            type: "ean/deleteAll",
            data: this.$store.state.campana.editCampana.id,
          });

          await this.getEansByCampana();
        }
      });
  }

  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar este EAN?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "ean/delete",
            data: item,
          });

          await this.getEansByCampana();
        }
      });
  }
  edit() {
    this.editModalShow = true;
  }

  handleTableChange = async (pagination, filters, sorter) => {
    
    this.$store.commit("ean/setCurrentPage", pagination.current);
    //this.getpage();

    await this.getpage();
  };

  pageChange(page: number) {
    
    this.$store.commit("ean/setCurrentPage", page);
    this.getpage();
  }
  pagesizeChange(pagesize: number) {
    
    this.$store.commit("ean/setPageSize", pagesize);
    this.getpage();
  }
  async getpage() {
    this.pagerequest.campanaId = this.campana.id;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
  }
  get pageSize() {
    return this.$store.state.ean.pageSize;
  }
  get totalCount() {
    return this.$store.state.ean.totalCount;
  }
  get currentPage() {
    return this.$store.state.ean.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("ean/setCurrentPage", page);
    this.getpage();
  }

  async created() {
    
    this.getpage();
  }

  async getEansByCampana() {
    this.loadingEans = true;
    this.pagerequest.campanaId = this.$store.state.campana.editCampana.id;
    this.pagerequest.maxResultCount = maxResults.maxResultCount;
    this.pagerequest.skipCount = maxResults.skipCount;

    await this.$store.dispatch({
      type: "campana/getAllEans",
      data: this.pagerequest,
    });

    this.eansByCampana = this.$store.state.campana.eansList;

    this.listEans = this.$store.state.ean.list.filter((x) => {
      return (
        this.eansByCampana
          .map((sel) => {
            return sel.id;
          })
          .includes(x.id) === false
      );
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.ean.totalCount;
    pagination.showTotal = () =>
      `${this.$store.state.ean.totalCount} EANs.`;
    pagination.pageSize = this.$store.state.ean.pageSize;
    pagination.current = this.$store.state.ean.currentPage;
    this.pagination = pagination;
    this.loadingEans = false;
  }
  columns = [
    {
      title: this.L("Titulo"),
      dataIndex: "titulo",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Código EAN"),
      dataIndex: "cod_Ean",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Marca"),
      dataIndex: "marca",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Categoria"),
      dataIndex: "categoria",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Importe Fijo"),
      dataIndex: "importe_Fijo",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Importe Porcentual"),
      dataIndex: "importe_Porcentual",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      scopedSlots: { customRender: "editBtn" },
      width: 10,
      title: "",
    },
    {
      scopedSlots: { customRender: "removeBtn" },
      width: 10,
      title: "",
    },
  ];
}
