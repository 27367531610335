
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Form } from "ant-design-vue";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import ConfiguracionSorteoCampana from "../../../store/entities/OZONE/configuracionSorteoCampana";

@Component({
  name: "SorteoCampanaComponent",
})
export default class SorteoCampanaComponent extends AbpBase {
  @Prop({ type: Object, required: true }) value!: ConfiguracionSorteoCampana;


  id: number = 0;
  loading: boolean = false;
  creating: boolean = false;
  pagerequestCampanas: PageRequest = new PageRequest();
  form: any = null;
  configuracionSorteo : ConfiguracionSorteoCampana;
  sorteoDefinition = {
    active: false,
    startDate: null as Date | null,
    endDate: null as Date | null,
    allowMultipleDraws: false,
    allowWinnersToWinAgain: false,
    numberOfWinners: 1,
    numberOfSubstitutes: 0,
  };

  formItemLayout: any = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  async created() {
    this.creating = true;
    if(this.value){
      this.configuracionSorteo = this.value;
      this.sorteoDefinition.active = true;
      this.sorteoDefinition.startDate = this.configuracionSorteo.fechaInicio  ;
      this.sorteoDefinition.endDate = this.configuracionSorteo.fechaFin;
      this.sorteoDefinition.allowMultipleDraws = this.configuracionSorteo.permiteMultiplesSorteos;
      this.sorteoDefinition.allowWinnersToWinAgain = this.configuracionSorteo.permiteRepetirGanador;
      this.sorteoDefinition.numberOfWinners = this.configuracionSorteo.numeroGanadores;
      this.sorteoDefinition.numberOfSubstitutes = this.configuracionSorteo.numeroSuplentes;
    }
    else{
      this.configuracionSorteo = new ConfiguracionSorteoCampana();
    }
    this.configuracionSorteo.campanaId = this.$store.state.campana.editCampana.id;
    this.creating = false;
  }

  mounted() {
    this.form = this.$form.createForm(this);
  }

  @Watch('sorteoDefinition', { deep: true, immediate: true })
  onSorteoDefinitionChanged(newVal) {
    if (!this.sorteoDefinition.active) {
      this.$emit("input", null);
      return;
    }
    console.log(this.sorteoDefinition.numberOfSubstitutes);
    let configuracionSorteo = this.configuracionSorteo;
    configuracionSorteo.fechaInicio = this.sorteoDefinition.startDate;
    configuracionSorteo.fechaFin = this.sorteoDefinition.endDate;
    configuracionSorteo.permiteMultiplesSorteos = this.sorteoDefinition.allowMultipleDraws;
    configuracionSorteo.permiteRepetirGanador = this.sorteoDefinition.allowWinnersToWinAgain;
    configuracionSorteo.numeroGanadores = this.sorteoDefinition.numberOfWinners;
    configuracionSorteo.numeroSuplentes = this.sorteoDefinition.numberOfSubstitutes;
    this.$emit("input", configuracionSorteo);

  }

  fechaInicioValidator(rule, value, callback) {
    if (value && value <= new Date()) {
      callback('La fecha de inicio debe ser mayor a hoy');
    } else {
      callback();
    }
  }

  endDateValidator(rule, value, callback) {
    const startDate = this.form.getFieldValue('startDate');
    if (value && startDate && value <= startDate) {
      callback('La fecha de fin debe ser mayor a la fecha de inicio');
    } else {
      callback();
    }
  }
}
