
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import CallCenter from '@/store/entities/OZONE/callCenter'
import PageRequest from '@/store/entities/page-request'
class PageCallCenterRequest extends PageRequest {
  defaultFromDisplayName: string
}
@Component
export default class SelectCallCenter extends AbpBase {
  @Prop({ type: Object, default: null }) value: CallCenter
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Object, default: null }) rules: Object
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  callCenter: CallCenter = null
  pagerequestCallCenters: PageCallCenterRequest = new PageCallCenterRequest()
  maxResultCountCallCenter = 10
  skipCountCallCenter = 0
  get callCenterList() {
    return this.$store.state.callCenter.list
  }
  get callCenterCount() {
    return this.$store.state.callCenter.totalCount
  }

  async created() {
    this.getCallCenterDropDown()
  }

  async getCallCenter(params: any) {
    this.pagerequestCallCenters.defaultFromDisplayName = params
    this.maxResultCountCallCenter = 10
    this.skipCountCallCenter = 0
    this.pagerequestCallCenters.maxResultCount = this.maxResultCountCallCenter
    this.pagerequestCallCenters.skipCount = this.skipCountCallCenter
    await this.$store.dispatch({
      type: 'callCenter/getAll',
      data: this.pagerequestCallCenters
    })
  }

  async nextPage(offset: number) {
    this.pagerequestCallCenters.skipCount = offset
    await this.$store.dispatch({
      type: 'callCenter/getAll',
      data: this.pagerequestCallCenters
    })
  }

  async getCallCenterDropDown() {
    this.pagerequestCallCenters.maxResultCount = this.maxResultCountCallCenter
    this.pagerequestCallCenters.skipCount = this.skipCountCallCenter
    await this.$store.dispatch({
      type: 'callCenter/getAll',
      data: this.pagerequestCallCenters
    })
  }

  callCenterRule = {
    callCenter: {
      // required: { required: this.required },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Path')),
      placeholder: this.L('Path'),
      trigger: 'blur'
    }
  }
}
