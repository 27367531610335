
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import BaseCheckbox from "../../../components/Inputs/BaseCheckbox.vue";
import Campana from "../../../store/entities/OZONE/campana";
import BaseDatePicker from "../../../components/Inputs/BaseDatePicker.vue";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import Multiselect from "vue-multiselect";
import Campo from "../../../store/entities/OZONE/campo";
import Cliente from "../../../store/entities/OZONE/cliente";
import SelectCliente from "../cliente/selectCliente.vue";
import SelectEmail from "../email/selectEmail.vue";
import Email from "../../../store/entities/OZONE/email";
import SelectCampo from "../campo/selectCampo.vue";
import CampanaTipo from "../../../store/entities/OZONE/campanaTipo";
// import Collapse from "../../../components/Collapse/Collapse.vue";
import CollapseItem from "../../../components/Collapse/CollapseItem.vue";
import EstadoCampana from "../../../store/entities/OZONE/estadoCampana";
import SelectMarca from "../marca/selectMarca.vue";
import Marca from "../../../store/entities/OZONE/marca";
import SelectEstados from "./select-estados.vue";
import ProgramacionTransferencia from "./programacion-transferencia.vue";
import SorteoCampanaComponent from "./sorteo-campana.vue";
import ProgramacionEnvios from "./programacion-envios.vue";
//import SelectGrupo from "./selectGrupo.vue";
import SelectGrupo from "./selectGrupo_new.vue";
import Ean from "../ean/ean.vue";
import Regalo from "../regalo/regalo.vue";
import EmailEstadoParticipacion from "./edit-email.vue";
import Documentos from "./edit-documentos.vue";
import EditCampanaTicket from "./edit-campana-ticket.vue";
import ReglasTicketDato from "../../../store/entities/OZONE/reglasTicketDato";
import ProgramacionTransferenciaCampana from "../../../store/entities/OZONE/programacionTransferencia";
import ProgramacionEnviosCampana from "../../../store/entities/OZONE/programacionEnvios";
import EditCallCenterCampana from "./edit-CallCenter.vue";
import PeopleHeader from "../../../components/People/people-header.vue";
import SelectImagen from "./selectImagen.vue";
import ValidateImagenCampana from "../../../store/entities/OZONE/validateImagenCampana";
import ControlDuplicados from "./controlDuplicados.vue";
import { GrupoTree } from "../../../store/entities/OZONE/grupo";
import ConfiguracionSorteoCampana from "../../../store/entities/OZONE/configuracionSorteoCampana";

//import  SelectPage  from 'v-selectpage'

class PageCamposRequest extends PageRequest {
  campanaId: number;
}
class PageEansRequest extends PageRequest {
  campanaId: number;
}

@Component({
  components: {
    Modal,
    BaseButton,
    BaseCheckbox,
    BaseDatePicker,
    Multiselect,
    SelectCliente,
    SelectEmail,
    SelectCampo,
    // Collapse,
    CollapseItem,
    SelectMarca,
    SelectEstados,
    Ean,
    SelectGrupo,
    EmailEstadoParticipacion,
    EditCampanaTicket,
    ProgramacionTransferencia,
    SorteoCampanaComponent ,
    PeopleHeader,
    EditCallCenterCampana,
    SelectImagen,
    Regalo,
    ProgramacionEnvios,
    ControlDuplicados,
    Documentos
  },
})
export default class EditCampana extends AbpBase {
  name: "edit-campana";
  // @Prop({ type: Boolean, default: false }) value: boolean
  // @Prop({ type: Boolean, default: false }) editing: boolean
  // @Prop({ type: String }) id: string
  pagerequest: PageRequest = new PageRequest();
  pageCamposRequest: PageCamposRequest = new PageCamposRequest();
  pageEansRequest: PageCamposRequest = new PageCamposRequest();
  camposRequest: PageCamposRequest = new PageCamposRequest();
  eansRequest: PageCamposRequest = new PageCamposRequest();
  regalosRequest: PageCamposRequest = new PageCamposRequest();
  campana: Campana = new Campana();
  programacion: ProgramacionTransferenciaCampana =     new ProgramacionTransferenciaCampana();
  configuracionSorteo: ConfiguracionSorteoCampana = new ConfiguracionSorteoCampana();
  configuracionSorteoOriginal: string;
  programacionEnvio: ProgramacionEnviosCampana =
    new ProgramacionEnviosCampana();
  title: String = "";
  activeKey: String = "1";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  campos: Array<any> = [];
  eans: Array<any> = [];
  camposSelected: Array<any> = [];
  camposByCampana: Array<any> = [];
  eansByCampana: Array<any> = [];
  regalosByCampana: Array<any> = [];
  listCampos: Array<any> = [];
  listEans: Array<any> = [];
  listRegalos: Array<any> = [];
  campo: Campo = new Campo();
  // ean: Ean = new Ean()
  cliente: Cliente = null;
  clienteId: number = 0;
  marca: Marca = null;
  email: Email = null;
  campanaTipo: CampanaTipo = null;
  estadoCampana: EstadoCampana = null;
  editing: boolean = false;
  loading: boolean = false;
  estadosList = [];
  estadosListUnselected = [];
  gruposList = [];
  gruposListUnselected = [];
  disabled: boolean = false;
  crearCampana: boolean = true;
  error: string = "";
  validateImagenCampana: ValidateImagenCampana = new ValidateImagenCampana();

  campanaTipoId: number = 0;

  get list() {
    return this.$store.state.campana.camposList;
  }

  get list_Eans() {
    return this.$store.state.campana.eansList;
  }

  get pageSize() {
    return this.$store.state.campana.pageSizeCampos;
  }

  get totalCount() {
    return this.$store.state.campana.totalCountCampos;
  }

  get currentPage() {
    return this.$store.state.campana.currentPageCampos;
  }

  get campanaTipoList() {
    return this.$store.state.campanaTipo.list;
  }

  get estadoCampanaList() {
    if (!this.editing) {
      return this.$store.state.estadoCampana.list.splice(2, 1);
    } else {
      return this.$store.state.estadoCampana.list;
    }
  }

  get estadosParticipacionForTree() {
    return this.$store.state.estadoParticipacion.estadoParticipacionTree;
  }

  get grupoForTree() {
    return this.$store.state.grupo.grupoTree;
  }

  set currentPage(page) {
    this.$store.commit("campana/setCurrentPageCampos", page);
    this.getpage();
    this.getCamposByCampana();
    this.getEansByCampana();
    this.getRegalosByCampana();
  }

  pageChange(page: number) {
    this.$store.commit("campo/setCurrentPageCampos", page);
    this.getpage();
    this.getCamposByCampana();
    this.getEansByCampana();
    this.getRegalosByCampana();
  }

  pagesizeChange(pagesize: number) {
    this.$store.commit("campo/setPageSize", pagesize);
    this.getpage();
  }

  async getpage() {
    this.pageCamposRequest.campanaId = this.campana.id;
    this.pageCamposRequest.maxResultCount = this.pageSize;
    this.pageCamposRequest.skipCount = (this.currentPage - 1) * this.pageSize;

    this.pageEansRequest.campanaId = this.campana.id;
    this.pageEansRequest.maxResultCount = this.pageSize;
    this.pageEansRequest.skipCount = (this.currentPage - 1) * this.pageSize;
    await this.$store.dispatch({
      type: "campana/getAllPagedCampos",
      data: this.pageCamposRequest,
    });
    await this.$store.dispatch({
      type: "campana/getAllEans",
      data: this.pageEansRequest,
    });
    this.campos = this.$store.state.campana.camposListPaged;
    this.eans = this.$store.state.campana.eansList;
  }

  async created() {
    await this.$store.dispatch({
      type: "campo/getAll",
      data: maxResults,
    });

    await this.$store.dispatch({
      type: "campanaTipo/getAll",
      data: null,
    });
    await this.$store.dispatch({
      type: "estadoCampana/getAll",
      data: null,
    });
  }
  form: any;

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "participacionForm" });
  }

  async onSubmit(){
    await this.save();
  }

  async saveConfiguracionSorteo() {
    
    if (JSON.stringify(this.configuracionSorteo) === this.configuracionSorteoOriginal){
      console.log("sin cambios en configuración sorteos");
      return;
    }

    if (!this.configuracionSorteo) {
    
      await this.$store.dispatch("campana/deleteConfiguracionSorteo", {
        campanaId: this.campana.id ,
      }).then(() => {});;
    return;
  }

  const { fechaInicio, fechaFin } = this.configuracionSorteo;
  
  if (fechaInicio && fechaFin) {
          await this.$store.dispatch({
        type: "campana/configurarSorteoCampana",
        data:  this.configuracionSorteo
      })
      .then(() => {})
      .catch(error => {
    console.log(error);
  })
      ;
  
  }
}

  async saveProgramacionTransferencia() {
    
    if (this.programacion == null) {
      await this.$store
        .dispatch({
          type: "campana/deleteProgramacionTransferencia",
          idCampana: this.campana.id ,
        })
        .then(() => {});
    } else {
      if (
        this.programacion &&
        this.programacion.fechaInicio &&
        this.programacion.fechaFin &&
        this.programacion.cuentasEmisorasId
      ) {
        await this.$store
          .dispatch({
            type: "campana/updateProgramacionTransferencia",
            data: this.programacion,
          })
          .then(() => {});
      }
    }
  }

  

  async saveProgramacionEnvio() {
    if (this.programacionEnvio == null) {
      await this.$store
        .dispatch({
          type: "campana/deleteProgramacionEnvios",
          data: { campanaId: this.campana.id },
        })
        .then(() => {});
    } else {
      if (this.programacionEnvio) {
        this.programacionEnvio.campanaId = this.campana.id;
        await this.$store
          .dispatch({
            type: "campana/updateProgramacionEnvios",
            data: this.programacionEnvio,
          })
          .then(() => {});
      }
    }
  }

  async save() {
    this.loading = true;
    this.campana.camposId = this.camposSelected.map((x) => x.id);
    this.campana.clienteId = this.cliente.id;
    this.campana.marcaId = this.marca.id;
    this.campana.emailId = this.email.id;
    this.campana.campanaTipoId = this.campanaTipo ? this.campanaTipo.id : 0;
    this.campana.estadoCampanaId = this.estadoCampana
      ? this.estadoCampana.id
      : 0;
    this.campana.estadoParticipacionTree = JSON.stringify(this.estadosList);
    this.campana.estadoParticipacionTreeUnselected = JSON.stringify(
      this.estadosListUnselected
    );
    this.campana.grupoTree = JSON.stringify(this.gruposList);
    this.campana.grupoTreeUnselected = JSON.stringify(
      this.gruposListUnselected
    );

    if (this.editing || !this.crearCampana) {
      this.campana.validateImagenCampana = this.$store.state.campana.editCampana.validateImagenCampana;
      await this.saveProgramacionTransferencia();
      await this.saveProgramacionEnvio();
      await this.saveConfiguracionSorteo();
      await this.$store
        .dispatch({
          type: "campana/update",
          data: this.campana,
        })
        .then(() => {
          this.close();
        });
    } else {
      await this.$store
        .dispatch({
          type: "campana/create",
          data: this.campana,
        })
        .then(async (campanaid) => {
          if (this.crearCampana) {
            await this.$store
              .dispatch({
                type: "campana/get",
                id: campanaid,
              })
              .then(() => {
                this.campana = Util.extend(
                  true,
                  {},
                  this.$store.state.campana.editCampana
                );
                this.campana.vozitelCampana = null;
              });

            this.crearCampana = false;
            this.editing = true;
            this.loading = false;
          } else {
            this.close();
          }
        });
    }

    // this.$emit('save-success')
  }

  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("DeleteCampoConfirm"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Yes"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          this.campos.splice(
            this.campos.findIndex((x) => x.id === item.id),
            1
          );
          this.camposSelected.splice(
            this.camposSelected.findIndex((x) => x.id === item.id),
            1
          );
          this.listCampos.push(item);
        }
      });
  }

  close() {
    this.resetFields();
    this.loading = false;
    this.$router.go(-1);
  }

  addCampo(selectedOption, item) {
    this.campos.push(selectedOption);
    this.camposSelected.push(selectedOption);
    this.listCampos.splice(
      this.listCampos.findIndex((x) => x.id === selectedOption.id),
      1
    );
  }

  async mounted() {
    this.loading = true;
    this.editing = this.$route.params.id != null;
    if (this.editing) {
      await this.$store
        .dispatch({
          type: "campana/get",
          id: this.$route.params.id,
        })
        .then(() => {
          this.campana = Util.extend(
            true,
            {},
            this.$store.state.campana.editCampana
          );
          this.campana.vozitelCampana = null;
          this.getEstadoParticipacionTree();
          this.getGrupoTree();
        });

      //this.campana = Util.extend(true, {}, this.$store.state.campana.editCampana)
      await this.getCliente();
      await this.getMarca();
      await this.getEmail();
      await this.getCamposByCampana();
      await this.getEansByCampana();
      await this.getRegalosByCampana();
      await this.getpage();
      await this.getConfiguracionSorteo();
      await this.getProgramacion();
      await this.getProgramacionEnvio();
      await this.getvalidateImagenCampana();
      //this.validateImagenCampana = this.$store.state.campana.validateImagenCampana
      this.campo = new Campo();
      this.camposSelected = this.$store.state.campana.camposList;
      this.campanaTipo = this.campanaTipoList.find(
        (x) => x.id === this.campana.campanaTipoId
      );
      this.estadoCampana = this.estadoCampanaList.find(
        (x) => x.id === this.campana.estadoCampanaId
      );
    } else {
      this.listCampos = this.$store.state.campo.list;
    }

    if (this.editing) {
      this.crearCampana = false;
      this.title = this.L("Editar Campaña");
    } else {
      this.title = this.L("Crear Campaña");
    }
    this.loading = false;
    this.setDisabled();
  }

  checkFormValidity() {
    //const valid = (this.$refs.campanaForm as any).checkValidity();
    //return valid;
    return true;
  }

  resetFields() {
    this.campana = new Campana();
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted(
      "OZONE.Campanas.Edit",
      "OZONE.Campanas.Create",
      "OZONE.Campanas.Delete"
    );
  }

  async getCliente() {
    await this.$store
      .dispatch({
        type: "cliente/get",
        id: this.campana.clienteId,
      })
      .then((cliente) => {
        this.cliente = cliente;
        this.clienteId = this.cliente.id;
      });
  }

  @Watch("cliente")
  clienteChange(value: Cliente) {
    this.clienteId = value.id;
    this.marca = null;
  }

  async getConfiguracionSorteo() {
    await this.$store
      .dispatch({
        type: "campana/getConfiguracionSorteo",
        id: this.campana.id,
      })
      .then((configuracionSorteo) => {
        this.configuracionSorteo = configuracionSorteo as ConfiguracionSorteoCampana;
        this.configuracionSorteoOriginal = JSON.stringify(this.configuracionSorteo);
      });
  }

  async getProgramacion() {
    await this.$store
      .dispatch({
        type: "campana/getProgramacionTransferencia",
        id: this.campana.id,
      })
      .then((programacion) => {
        console.log(programacion);
        this.programacion = programacion as ProgramacionTransferenciaCampana;
      });
  }

  async getProgramacionEnvio() {
    await this.$store
      .dispatch({
        type: "campana/getProgramacionEnvios",
        data: {campanaId: this.campana.id},
      })
      .then((programacion) => {
        console.log(programacion);
        this.programacionEnvio = programacion as ProgramacionEnviosCampana;

      });
  }
  
  async getvalidateImagenCampana() {
    await this.$store
      .dispatch({
        type: "campana/getvalidateImagenCampana",
        id: this.campana.id,
      })
      .then((validateImagenCampana) => {
        console.log(validateImagenCampana);
        this.validateImagenCampana =
          validateImagenCampana as ValidateImagenCampana;
      });
  }

  async getMarca() {
    await this.$store
      .dispatch({
        type: "marca/get",
        id: this.campana.marcaId,
      })
      .then((marca) => {
        this.marca = marca;
      });
  }

  async getEmail() {
    await this.$store
      .dispatch({
        type: "email/get",
        id: this.campana.emailId,
      })
      .then((email) => {
        this.email = email;
      });
  }

  async getCamposByCampana() {
    this.camposRequest.campanaId = this.campana.id;
    this.camposRequest.maxResultCount = maxResults.maxResultCount;
    this.camposRequest.skipCount = maxResults.skipCount;
    await this.$store.dispatch({
      type: "campana/getAllCampos",
      data: this.camposRequest,
    });
    this.camposByCampana = this.$store.state.campana.camposList;

    this.listCampos = this.$store.state.campo.list.filter((x) => {
      return (
        this.camposByCampana
          .map((sel) => {
            return sel.id;
          })
          .includes(x.id) === false
      );
    });
  }

  async getEansByCampana() {
    this.eansRequest.campanaId = this.campana.id;
    this.eansRequest.maxResultCount = maxResults.maxResultCount;
    this.eansRequest.skipCount = maxResults.skipCount;

    await this.$store.dispatch({
      type: "campana/getAllEans",
      data: this.eansRequest,
    });
    this.eansByCampana = this.$store.state.campana.eansList;

    this.listEans = this.$store.state.ean.list.filter((x) => {
      return (
        this.eansByCampana
          .map((sel) => {
            return sel.id;
          })
          .includes(x.id) === false
      );
    });
  }
  
  async getRegalosByCampana() {
    this.regalosRequest.campanaId = this.campana.id;
    this.regalosRequest.maxResultCount = maxResults.maxResultCount;
    this.regalosRequest.skipCount = maxResults.skipCount;

    await this.$store.dispatch({
      type: "campana/getAllRegalo",
      data: this.regalosRequest,
    });
    this.regalosByCampana = this.$store.state.campana.regalosList;

    this.listRegalos = this.$store.state.regalo.list.filter((x) => {
      return (
        this.regalosByCampana
          .map((sel) => {
            return sel.id;
          })
          .includes(x.id) === false
      );
    });
  }

  getEstadoParticipacionTree() {
    this.estadosList = this.campana.estadoParticipacionTree
      ? JSON.parse(this.campana.estadoParticipacionTree)
      : [];
    this.estadosListUnselected = this.campana.estadoParticipacionTreeUnselected
      ? JSON.parse(this.campana.estadoParticipacionTreeUnselected)
      : [];
  }

  getGrupoTree() {
    this.gruposList = this.campana.grupoTree
      ? JSON.parse(this.campana.grupoTree)
      : [];
    this.gruposListUnselected = this.campana.grupoTreeUnselected
      ? JSON.parse(this.campana.grupoTreeUnselected)
      : [];
  }

  async estadosSinSeleccionar(value: any) {
    this.estadosListUnselected = value;
    this.campana.camposId = this.camposSelected.map((x) => x.id);

    this.campana.estadoParticipacionTreeUnselected = JSON.stringify(
      this.estadosListUnselected
    );
  }

  async estadosSeleccionados(value: any) {
    this.estadosList = value;
    this.campana.estadoParticipacionTree = JSON.stringify(this.estadosList);
    this.campana.camposId = this.camposSelected.map((x) => x.id);

    await this.$store.dispatch({
      type: "campana/update",
      data: this.campana,
    });
  }

  async gruposSinSeleccionar(value: any) {
    this.gruposListUnselected = value;

    this.campana.grupoTreeUnselected = JSON.stringify(
      this.gruposListUnselected
    );
    this.campana.camposId = this.camposSelected.map((x) => x.id);
  }

  /**
   * Receptor del evento desde selectGrupo_new
  */
  addSelectedCampos(campos: Array<GrupoTree>){
    this.gruposList = campos;
  }

  async gruposSeleccionados(value: any) {
    this.gruposList = value;
    this.campana.grupoTree = JSON.stringify(this.gruposList);
    this.campana.camposId = this.camposSelected.map((x) => x.id);
    await this.$store.dispatch({
      type: "campana/update",
      data: this.campana,
    });
  }

  origin(value: any) {
    this.estadosListUnselected = value;
    this.gruposListUnselected = value;
  }

  ticketRules(rules) {
    if (rules.length > 0) {
      rules.map((rule) => {
        let regla = new ReglasTicketDato();
        regla.id = rule.id;
        regla.reglasTicketId = rule.reglasTicketId;
        regla.campoId = rule.campoId;
        regla.regla = JSON.stringify(rule.regla);
      });
    }
  }

  getColumns() {
    return [
      {
        label: this.L("Nombre"),
        key: "nombre",
        type: "select",
        options: this.$store.state.campo.list.map((x) => x.nombre),
        required: true,
      },
      {
        key: "actions",
        label: "Actions",
        thClass: "text-center w-25",
        tdClass: "text-center w-25",
      },
    ];
  }

  columns = [
    {
      label: this.L("Nombre"),
      key: "nombre",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      key: "actions",
      label: "Actions",
      thClass: "text-center w-25",
      tdClass: "text-center w-25",
    },
  ];

  campanaRule = {
    nombre: {
      required: { required: true, min: 2 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Nombre")),
      placeholder: this.L("Nombre"),
      trigger: "blur",
    },
    cliente: {
      required: { filtrableDropdownRequired: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Cliente")),
      placeholder: this.L("Cliente"),
      trigger: "blur",
    },
    grupo: {
      required: { required: false },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Grupo")),
      placeholder: this.L("Grupo"),
      trigger: "blur",
    },
    marca: {
      required: { filtrableDropdownRequired: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Marca")),
      placeholder: this.L("Marca"),
      trigger: "blur",
    },
    email: {
      required: { filtrableDropdownRequired: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Email")),
      placeholder: this.L("Email"),
      trigger: "blur",
    },
    campanaTipo: {
      required: { filtrableDropdownRequired: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("CampanaTipo")),
      placeholder: this.L("CampanaTipo"),
      trigger: "blur",
    },
    estadoCampana: {
      required: { filtrableDropdownRequired: true },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("EstadoCampana")
      ),
      placeholder: this.L("EstadoCampana"),
      trigger: "blur",
    },
    fechaFin: {
      required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("FechaFin")),
      placeholder: this.L("FechaFin"),
      trigger: "blur",
      type: "date",
    },
    fechaInicio: {
      required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("FechaInicio")),
      placeholder: this.L("FechaInicio"),
      trigger: "blur",
      type: "date",
    },
    pais: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Pais")),
      placeholder: this.L("Pais"),
      trigger: "blur",
    },
    axapta: {
      required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Axapta")),
      placeholder: this.L("Ekon"),
      trigger: "blur",
    },
    presupuesto: {
      required: { required: false },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Presupuesto")),
      placeholder: this.L("Presupuesto"),
      trigger: "blur",
    },
    volumen: {
      required: { required: false },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("Volumen")
      ),
      placeholder: this.L("Volumen"),
      trigger: "blur",
    },
    fechaGuardadoDatos: {
      required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("FechaGuardadoDatos")),
      placeholder: this.L("Fecha de Guardado de Datos"),
      trigger: "blur",
    },
    emailManager: {
      required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("EmailManager")),
      placeholder: this.L("Email del Manager"),
      trigger: "blur",
    }
  };
}

