
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Campo from '@/store/entities/OZONE/campo'
import PageRequest from '@/store/entities/page-request'
class PageCampoRequest extends PageRequest {
  filter: string
  campanaId: number
}
@Component
export default class SelectCampo extends AbpBase {
  @Prop({ type: Object, default: null }) value: Campo
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Boolean, default: false }) required: Boolean
  @Prop({ type: String, default: null }) label: string
  @Prop({ type: Number, default: 0 }) campanaId: number

  campo: Campo = null
  pagerequestCampos: PageCampoRequest = new PageCampoRequest()
  maxResultCountCampo = 10
  skipCountCampo = 0
  get campoList() {
    return this.$store.state.campo.list
  }
  get campoCount() {
    return this.$store.state.campo.totalCount
  }

  async created() {
    this.getCamposDropDown()
  }

  async getCampos(params: any) {
    this.pagerequestCampos.filter = params
    this.maxResultCountCampo = 10
    this.skipCountCampo = 0
    this.pagerequestCampos.maxResultCount = this.maxResultCountCampo
    this.pagerequestCampos.skipCount = this.skipCountCampo
    this.pagerequestCampos.campanaId = this.campanaId
    await this.$store.dispatch({
      type: 'campo/getAll',
      data: this.pagerequestCampos
    })
  }

  async nextPage(offset: number) {
    this.pagerequestCampos.skipCount = offset
    this.pagerequestCampos.campanaId = this.campanaId
    await this.$store.dispatch({
      type: 'campo/getAll',
      data: this.pagerequestCampos
    })
  }

  async getCamposDropDown() {
    if (this.campanaId) {
      this.pagerequestCampos.maxResultCount = this.maxResultCountCampo
      this.pagerequestCampos.skipCount = this.skipCountCampo
      this.pagerequestCampos.campanaId = this.campanaId
      await this.$store.dispatch({
        type: 'campo/getAll',
        data: this.pagerequestCampos
      })
    }
  }

  campoRule = {
    campo: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Nombre')),
      placeholder: this.L('Nombre'),
      trigger: 'blur'
    }
  }
}
